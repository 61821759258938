*,
:before,
:after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb
}

:before,
:after {
    --tw-content: ""
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
samp,
pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

button,
select {
    text-transform: none
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

progress {
    vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0
}

fieldset {
    margin: 0;
    padding: 0
}

legend {
    padding: 0
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0
}

textarea {
    resize: vertical
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;
    color: #9ca3af
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

button,
[role=button] {
    cursor: pointer
}

:disabled {
    cursor: default
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle
}

img,
video {
    max-width: 100%;
    height: auto
}

[hidden] {
    display: none
}

[type=text],
[type=email],
[type=url],
[type=password],
[type=number],
[type=date],
[type=datetime-local],
[type=month],
[type=search],
[type=tel],
[type=time],
[type=week],
[multiple],
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 #0000
}

[type=text]:focus,
[type=email]:focus,
[type=url]:focus,
[type=password]:focus,
[type=number]:focus,
[type=date]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=week]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #2563eb
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #6b7280;
    opacity: 1
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #6b7280;
    opacity: 1
}

input::placeholder,
textarea::placeholder {
    color: #6b7280;
    opacity: 1
}

::-webkit-datetime-edit-fields-wrapper {
    padding: 0
}

::-webkit-date-and-time-value {
    min-height: 1.5em
}

::-webkit-datetime-edit,
::-webkit-datetime-edit-year-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute-field,
::-webkit-datetime-edit-second-field,
::-webkit-datetime-edit-millisecond-field,
::-webkit-datetime-edit-meridiem-field {
    padding-top: 0;
    padding-bottom: 0
}

select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right .5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact
}

[multiple] {
    background-image: initial;
    background-position: initial;
    background-repeat: unset;
    background-size: initial;
    padding-right: .75rem;
    -webkit-print-color-adjust: unset;
    color-adjust: unset
}

[type=checkbox],
[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #2563eb;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    --tw-shadow: 0 0 #0000
}

[type=checkbox] {
    border-radius: 0
}

[type=radio] {
    border-radius: 100%
}

[type=checkbox]:focus,
[type=radio]:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow)
}

[type=checkbox]:checked,
[type=radio]:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

[type=checkbox]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")
}

[type=radio]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e")
}

[type=checkbox]:checked:hover,
[type=checkbox]:checked:focus,
[type=radio]:checked:hover,
[type=radio]:checked:focus {
    border-color: transparent;
    background-color: currentColor
}

[type=checkbox]:indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

[type=checkbox]:indeterminate:hover,
[type=checkbox]:indeterminate:focus {
    border-color: transparent;
    background-color: currentColor
}

[type=file] {
    background: unset;
    border-color: inherit;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-size: unset;
    line-height: inherit
}

[type=file]:focus {
    outline: 1px auto -webkit-focus-ring-color
}

*,
:before,
:after {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

.container {
    width: 100%
}

@media (min-width: 375px) {
    .container {
        max-width: 375px
    }
}

@media (min-width: 640px) {
    .container {
        max-width: 640px
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px
    }
}

@media (min-width: 1536px) {
    .container {
        max-width: 1536px
    }
}

.prose {
    color: var(--tw-prose-body);
    max-width: 65ch
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
    color: var(--tw-prose-lead);
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em
}

.prose :where(a):not(:where([class~="not-prose"] *)) {
    color: var(--tw-prose-links);
    text-decoration: underline;
    font-weight: 500
}

.prose :where(strong):not(:where([class~="not-prose"] *)) {
    color: var(--tw-prose-bold);
    font-weight: 600
}

.prose :where(ol):not(:where([class~="not-prose"] *)) {
    list-style-type: decimal;
    padding-left: 1.625em
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
    list-style-type: upper-alpha
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
    list-style-type: lower-alpha
}

.prose :where(ol[type="A"s]):not(:where([class~="not-prose"] *)) {
    list-style-type: upper-alpha
}

.prose :where(ol[type="a"s]):not(:where([class~="not-prose"] *)) {
    list-style-type: lower-alpha
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
    list-style-type: upper-roman
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
    list-style-type: lower-roman
}

.prose :where(ol[type="I"s]):not(:where([class~="not-prose"] *)) {
    list-style-type: upper-roman
}

.prose :where(ol[type="i"s]):not(:where([class~="not-prose"] *)) {
    list-style-type: lower-roman
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
    list-style-type: decimal
}

.prose :where(ul):not(:where([class~="not-prose"] *)) {
    list-style-type: disc;
    padding-left: 1.625em
}

.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
    font-weight: 400;
    color: var(--tw-prose-counters)
}

.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
    color: var(--tw-prose-bullets)
}

.prose :where(hr):not(:where([class~="not-prose"] *)) {
    border-color: var(--tw-prose-hr);
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em
}

.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
    font-weight: 500;
    font-style: italic;
    color: var(--tw-prose-quotes);
    border-left-width: .25rem;
    border-left-color: var(--tw-prose-quote-borders);
    quotes: "\201c""\201d""\2018""\2019";
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *)):before {
    content: open-quote
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)):after {
    content: close-quote
}

.prose :where(h1):not(:where([class~="not-prose"] *)) {
    color: var(--tw-prose-headings);
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: .8888889em;
    line-height: 1.1111111
}

.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
    font-weight: 900
}

.prose :where(h2):not(:where([class~="not-prose"] *)) {
    color: var(--tw-prose-headings);
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333
}

.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
    font-weight: 800
}

.prose :where(h3):not(:where([class~="not-prose"] *)) {
    color: var(--tw-prose-headings);
    font-weight: 600;
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: .6em;
    line-height: 1.6
}

.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
    font-weight: 700
}

.prose :where(h4):not(:where([class~="not-prose"] *)) {
    color: var(--tw-prose-headings);
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: .5em;
    line-height: 1.5
}

.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
    font-weight: 700
}

.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0
}

.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
    color: var(--tw-prose-captions);
    font-size: .875em;
    line-height: 1.4285714;
    margin-top: .8571429em
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
    color: var(--tw-prose-code);
    font-weight: 600;
    font-size: .875em
}

.prose :where(code):not(:where([class~="not-prose"] *)):before {
    content: "`"
}

.prose :where(code):not(:where([class~="not-prose"] *)):after {
    content: "`"
}

.prose :where(a code):not(:where([class~="not-prose"] *)) {
    color: var(--tw-prose-links)
}

.prose :where(pre):not(:where([class~="not-prose"] *)) {
    color: var(--tw-prose-pre-code);
    background-color: var(--tw-prose-pre-bg);
    overflow-x: auto;
    font-weight: 400;
    font-size: .875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: .375rem;
    padding: .8571429em 1.1428571em
}

.prose :where(pre code):not(:where([class~="not-prose"] *)) {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):before {
    content: none
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):after {
    content: none
}

.prose :where(table):not(:where([class~="not-prose"] *)) {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: .875em;
    line-height: 1.7142857
}

.prose :where(thead):not(:where([class~="not-prose"] *)) {
    border-bottom-width: 1px;
    border-bottom-color: var(--tw-prose-th-borders)
}

.prose :where(thead th):not(:where([class~="not-prose"] *)) {
    color: var(--tw-prose-headings);
    font-weight: 600;
    vertical-align: bottom;
    padding-right: .5714286em;
    padding-bottom: .5714286em;
    padding-left: .5714286em
}

.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
    border-bottom-width: 1px;
    border-bottom-color: var(--tw-prose-td-borders)
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
    border-bottom-width: 0
}

.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
    vertical-align: baseline;
    padding: .5714286em
}

.prose {
    --tw-prose-body: #374151;
    --tw-prose-headings: #111827;
    --tw-prose-lead: #4b5563;
    --tw-prose-links: #111827;
    --tw-prose-bold: #111827;
    --tw-prose-counters: #6b7280;
    --tw-prose-bullets: #d1d5db;
    --tw-prose-hr: #e5e7eb;
    --tw-prose-quotes: #111827;
    --tw-prose-quote-borders: #e5e7eb;
    --tw-prose-captions: #6b7280;
    --tw-prose-code: #111827;
    --tw-prose-pre-code: #e5e7eb;
    --tw-prose-pre-bg: #1f2937;
    --tw-prose-th-borders: #d1d5db;
    --tw-prose-td-borders: #e5e7eb;
    --tw-prose-invert-body: #d1d5db;
    --tw-prose-invert-headings: #fff;
    --tw-prose-invert-lead: #9ca3af;
    --tw-prose-invert-links: #fff;
    --tw-prose-invert-bold: #fff;
    --tw-prose-invert-counters: #9ca3af;
    --tw-prose-invert-bullets: #4b5563;
    --tw-prose-invert-hr: #374151;
    --tw-prose-invert-quotes: #f3f4f6;
    --tw-prose-invert-quote-borders: #374151;
    --tw-prose-invert-captions: #9ca3af;
    --tw-prose-invert-code: #fff;
    --tw-prose-invert-pre-code: #d1d5db;
    --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
    --tw-prose-invert-th-borders: #4b5563;
    --tw-prose-invert-td-borders: #374151;
    font-size: 1rem;
    line-height: 1.75
}

.prose :where(p):not(:where([class~="not-prose"] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em
}

.prose :where(img):not(:where([class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em
}

.prose :where(video):not(:where([class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em
}

.prose :where(figure):not(:where([class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em
}

.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
    font-size: .875em
}

.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
    font-size: .9em
}

.prose :where(li):not(:where([class~="not-prose"] *)) {
    margin-top: .5em;
    margin-bottom: .5em
}

.prose :where(ol > li):not(:where([class~="not-prose"] *)) {
    padding-left: .375em
}

.prose :where(ul > li):not(:where([class~="not-prose"] *)) {
    padding-left: .375em
}

.prose>:where(ul > li p):not(:where([class~="not-prose"] *)) {
    margin-top: .75em;
    margin-bottom: .75em
}

.prose>:where(ul > li > *:first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.25em
}

.prose>:where(ul > li > *:last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.25em
}

.prose>:where(ol > li > *:first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.25em
}

.prose>:where(ol > li > *:last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.25em
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
    margin-top: .75em;
    margin-bottom: .75em
}

.prose :where(hr + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0
}

.prose :where(h2 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0
}

.prose :where(h3 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0
}

.prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0
}

.prose :where(tbody td:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0
}

.prose :where(tbody td:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0
}

.prose>:where(:first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 0
}

.prose>:where(:last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 0
}

#menubox iframe {
    width: 100% !important
}

.htmx-with-icon {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.htmx-with-icon .spinner {
    position: absolute;
    opacity: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

form.htmx-request .htmx-with-icon .spinner {
    opacity: 1
}

form.htmx-request .htmx-with-icon .htmx-icon {
    opacity: 0
}

a.htmx-request.htmx-with-icon .spinner {
    opacity: 1
}

a.htmx-request.htmx-with-icon .htmx-icon {
    opacity: 0
}

button.htmx-request.htmx-with-icon .spinner {
    position: relative;
    opacity: 1
}

button.htmx-request.htmx-with-icon .htmx-icon {
    opacity: 0
}

.no-scrollbar::-webkit-scrollbar {
    display: none
}

.no-scrollbar {
    -ms-overflow-style: none
}

.visible {
    visibility: visible
}

.invisible {
    visibility: hidden
}

.fixed {
    position: fixed
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.sticky {
    position: -webkit-sticky;
    position: sticky
}

.inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
}

.-top-24 {
    top: -6rem
}

.right-6 {
    right: 1.5rem
}

.top-0 {
    top: 0px
}

.right-0 {
    right: 0px
}

.top-6 {
    top: 1.5rem
}

.left-6 {
    left: 1.5rem
}

.bottom-full {
    bottom: 100%
}

.right-4 {
    right: 1rem
}

.right-5 {
    right: 1.25rem
}

.right-3 {
    right: .75rem
}

.-right-3 {
    right: -.75rem
}

.left-0 {
    left: 0px
}

.bottom-0 {
    bottom: 0px
}

.top-\[120px\] {
    top: 120px
}

.top-16 {
    top: 4rem
}

.top-3 {
    top: .75rem
}

.top-1\/2 {
    top: 50%
}

.-right-12 {
    right: -3rem
}

.left-1\/2 {
    left: 50%
}

.-top-\[9999px\] {
    top: -9999px
}

.top-10 {
    top: 2.5rem
}

.right-10 {
    right: 2.5rem
}

.-top-40 {
    top: -10rem
}

.bottom-9 {
    bottom: 2.25rem
}

.-right-\[8\.5rem\] {
    right: -8.5rem
}

.top-\[22px\] {
    top: 22px
}

.top-32 {
    top: 8rem
}

.-left-6 {
    left: -1.5rem
}

.top-28 {
    top: 7rem
}

.-top-96 {
    top: -24rem
}

.bottom-1\/2 {
    bottom: 50%
}

.bottom-28 {
    bottom: 7rem
}

.bottom-8 {
    bottom: 2rem
}

.bottom-\[calc\(50\%-60px\)\] {
    bottom: calc(50% - 60px)
}

.-right-10 {
    right: -2.5rem
}

.top-4 {
    top: 1rem
}

.-bottom-6 {
    bottom: -1.5rem
}

.left-\[2px\] {
    left: 2px
}

.top-\[150px\] {
    top: 150px
}

.left-3 {
    left: .75rem
}

.bottom-3 {
    bottom: .75rem
}

.-top-full {
    top: -100%
}

.right-\[105\%\] {
    right: 105%
}

.top-\[2px\] {
    top: 2px
}

.-left-0 {
    left: -0px
}

.left-10 {
    left: 2.5rem
}

.left-2 {
    left: .5rem
}

.-left-\[25px\] {
    left: -25px
}

.bottom-6 {
    bottom: 1.5rem
}

.right-1 {
    right: .25rem
}

.top-1 {
    top: .25rem
}

.-bottom-4 {
    bottom: -1rem
}

.top-1\/3 {
    top: 33.333333%
}

.z-50 {
    z-index: 50
}

.z-10 {
    z-index: 10
}

.z-20 {
    z-index: 20
}

.z-40 {
    z-index: 40
}

.z-0,
.-z-0 {
    z-index: 0
}

.z-30 {
    z-index: 30
}

.-z-10 {
    z-index: -10
}

.order-2 {
    order: 2
}

.order-1 {
    order: 1
}

.-order-1 {
    order: -1
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-first {
    order: -9999
}

.col-span-10 {
    grid-column: span 10 / span 10
}

.col-span-12 {
    grid-column: span 12 / span 12
}

.col-span-2 {
    grid-column: span 2 / span 2
}

.col-span-5 {
    grid-column: span 5 / span 5
}

.col-span-7 {
    grid-column: span 7 / span 7
}

.col-span-6 {
    grid-column: span 6 / span 6
}

.col-span-4 {
    grid-column: span 4 / span 4
}

.col-span-8 {
    grid-column: span 8 / span 8
}

.col-span-3 {
    grid-column: span 3 / span 3
}

.col-start-2 {
    grid-column-start: 2
}

.col-start-1 {
    grid-column-start: 1
}

.col-start-8 {
    grid-column-start: 8
}

.col-start-6 {
    grid-column-start: 6
}

.col-start-4 {
    grid-column-start: 4
}

.row-span-7 {
    grid-row: span 7 / span 7
}

.row-span-8 {
    grid-row: span 8 / span 8
}

.row-span-9 {
    grid-row: span 9 / span 9
}

.row-span-10 {
    grid-row: span 10 / span 10
}

.row-span-11 {
    grid-row: span 11 / span 11
}

.row-span-12 {
    grid-row: span 12 / span 12
}

.row-start-2 {
    grid-row-start: 2
}

.row-start-1 {
    grid-row-start: 1
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
}

.my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
}

.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}

.my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem
}

.my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem
}

.my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem
}

.mx-0 {
    margin-left: 0;
    margin-right: 0
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0
}

.mb-6 {
    margin-bottom: 1.5rem
}

.mt-10 {
    margin-top: 2.5rem
}

.mt-2 {
    margin-top: .5rem
}

.mt-4 {
    margin-top: 1rem
}

.mt-3 {
    margin-top: .75rem
}

.mt-14 {
    margin-top: 3.5rem
}

.ml-1\.5 {
    margin-left: .375rem
}

.ml-1 {
    margin-left: .25rem
}

.ml-auto {
    margin-left: auto
}

.mt-auto {
    margin-top: auto
}

.mr-6 {
    margin-right: 1.5rem
}

.mt-8 {
    margin-top: 2rem
}

.mt-1 {
    margin-top: .25rem
}

.-mb-2 {
    margin-bottom: -.5rem
}

.mt-6 {
    margin-top: 1.5rem
}

.-mt-\[80vh\] {
    margin-top: -80vh
}

.-mt-\[350px\] {
    margin-top: -350px
}

.mb-10 {
    margin-bottom: 2.5rem
}

.mr-2 {
    margin-right: .5rem
}

.mt-20 {
    margin-top: 5rem
}

.-mt-6 {
    margin-top: -1.5rem
}

.mt-\[200px\] {
    margin-top: 200px
}

.mb-1 {
    margin-bottom: .25rem
}

.mt-16 {
    margin-top: 4rem
}

.mb-16 {
    margin-bottom: 4rem
}

.mb-3 {
    margin-bottom: .75rem
}

.ml-2 {
    margin-left: .5rem
}

.mb-12 {
    margin-bottom: 3rem
}

.mt-12 {
    margin-top: 3rem
}

.-mt-3 {
    margin-top: -.75rem
}

.mb-4 {
    margin-bottom: 1rem
}

.mb-8 {
    margin-bottom: 2rem
}

.mr-1 {
    margin-right: .25rem
}

.mb-2 {
    margin-bottom: .5rem
}

.-mr-\[20px\] {
    margin-right: -20px
}

.-mr-\[40\%\] {
    margin-right: -40%
}

.ml-3 {
    margin-left: .75rem
}

.-mt-2 {
    margin-top: -.5rem
}

.mt-5 {
    margin-top: 1.25rem
}

.mt-0 {
    margin-top: 0
}

.mr-auto {
    margin-right: auto
}

.mb-0 {
    margin-bottom: 0
}

.mt-\[6px\] {
    margin-top: 6px
}

.box-border {
    box-sizing: border-box
}

.box-content {
    box-sizing: content-box
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}

.inline {
    display: inline
}

.flex {
    display: flex
}

.inline-flex {
    display: inline-flex
}

.table {
    display: table
}

.grid {
    display: grid
}

.contents {
    display: contents
}

.hidden {
    display: none
}

.aspect-square {
    aspect-ratio: 1 / 1
}

.aspect-video {
    aspect-ratio: 16 / 9
}

.h-1 {
    height: .25rem
}

.h-fit {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

.h-64 {
    height: 16rem
}

.h-full {
    height: 100%
}

.h-36 {
    height: 9rem
}

.h-4 {
    height: 1rem
}

.h-5 {
    height: 1.25rem
}

.h-\[1px\] {
    height: 1px
}

.h-10 {
    height: 2.5rem
}

.h-6 {
    height: 1.5rem
}

.h-auto {
    height: auto
}

.h-3 {
    height: .75rem
}

.h-96 {
    height: 24rem
}

.h-14 {
    height: 3.5rem
}

.h-2 {
    height: .5rem
}

.h-20 {
    height: 5rem
}

.h-16 {
    height: 4rem
}

.h-\[calc\(80vh-104px\)\] {
    height: calc(80vh - 104px)
}

.h-\[100vh\] {
    height: 100vh
}

.h-\[20\%\] {
    height: 20%
}

.h-12 {
    height: 3rem
}

.h-\[4px\] {
    height: 4px
}

.h-\[60\%\] {
    height: 60%
}

.h-8 {
    height: 2rem
}

.h-60 {
    height: 15rem
}

.h-\[6px\] {
    height: 6px
}

.h-\[200px\] {
    height: 200px
}

.h-32 {
    height: 8rem
}

.h-\[calc\(100vh-104px\)\] {
    height: calc(100vh - 104px)
}

.h-\[12px\] {
    height: 12px
}

.h-\[90px\] {
    height: 90px
}

.h-\[24px\] {
    height: 24px
}

.h-\[16px\] {
    height: 16px
}

.h-52 {
    height: 13rem
}

.h-9 {
    height: 2.25rem
}

.h-\[136px\] {
    height: 136px
}

.h-\[52px\] {
    height: 52px
}

.max-h-0 {
    max-height: 0px
}

.max-h-\[900px\] {
    max-height: 900px
}

.min-h-\[calc\(100vh-112px\)\] {
    min-height: calc(100vh - 112px)
}

.min-h-\[calc\(100vh-120px\)\] {
    min-height: calc(100vh - 120px)
}

.min-h-screen {
    min-height: 100vh
}

.min-h-\[40px\] {
    min-height: 40px
}

.min-h-\[400px\] {
    min-height: 400px
}

.min-h-\[140px\] {
    min-height: 140px
}

.min-h-\[130px\] {
    min-height: 130px
}

.min-h-\[150px\] {
    min-height: 150px
}

.min-h-\[110px\] {
    min-height: 110px
}

.min-h-\[96px\] {
    min-height: 96px
}

.min-h-\[60px\] {
    min-height: 60px
}

.min-h-\[64px\] {
    min-height: 64px
}

.min-h-\[350px\] {
    min-height: 350px
}

.min-h-max {
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content
}

.min-h-\[48px\] {
    min-height: 48px
}

.min-h-\[50px\] {
    min-height: 50px
}

.w-full {
    width: 100%
}

.w-44 {
    width: 11rem
}

.w-auto {
    width: auto
}

.w-28 {
    width: 7rem
}

.w-40 {
    width: 10rem
}

.w-10 {
    width: 2.5rem
}

.w-4 {
    width: 1rem
}

.w-2\.5 {
    width: .625rem
}

.w-2 {
    width: .5rem
}

.w-6 {
    width: 1.5rem
}

.w-5 {
    width: 1.25rem
}

.w-3 {
    width: .75rem
}

.w-screen {
    width: 100vw
}

.w-14 {
    width: 3.5rem
}

.w-20 {
    width: 5rem
}

.w-32 {
    width: 8rem
}

.w-8 {
    width: 2rem
}

.w-12 {
    width: 3rem
}

.w-52 {
    width: 13rem
}

.w-36 {
    width: 9rem
}

.w-\[6px\] {
    width: 6px
}

.w-\[calc\(100vw\)\] {
    width: 100vw
}

.w-\[150px\] {
    width: 150px
}

.w-72 {
    width: 18rem
}

.w-60 {
    width: 15rem
}

.w-\[12px\] {
    width: 12px
}

.w-1\/3 {
    width: 33.333333%
}

.w-2\/3 {
    width: 66.666667%
}

.w-\[90px\] {
    width: 90px
}

.w-16 {
    width: 4rem
}

.w-\[8px\] {
    width: 8px
}

.w-fit {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.w-\[calc\(100\%\+4rem\)\] {
    width: calc(100% + 4rem)
}

.w-\[70px\] {
    width: 70px
}

.w-9 {
    width: 2.25rem
}

.w-\[53px\] {
    width: 53px
}

.w-\[calc\(100\%-80px\)\] {
    width: calc(100% - 80px)
}

.w-24 {
    width: 6rem
}

.w-\[80px\] {
    width: 80px
}

.w-\[88px\] {
    width: 88px
}

.min-w-\[1\.5rem\] {
    min-width: 1.5rem
}

.max-w-xs {
    max-width: 20rem
}

.max-w-none {
    max-width: none
}

.max-w-sm {
    max-width: 24rem
}

.max-w-fit {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content
}

.max-w-md {
    max-width: 28rem
}

.max-w-\[16rem\] {
    max-width: 16rem
}

.max-w-6xl {
    max-width: 72rem
}

.max-w-3xl {
    max-width: 48rem
}

.max-w-\[160px\] {
    max-width: 160px
}

.max-w-\[640px\] {
    max-width: 640px
}

.max-w-\[480px\] {
    max-width: 480px
}

.max-w-7xl {
    max-width: 80rem
}

.max-w-\[320px\] {
    max-width: 320px
}

.max-w-xl {
    max-width: 36rem
}

.max-w-lg {
    max-width: 32rem
}

.max-w-2xl {
    max-width: 42rem
}

.max-w-\[420px\] {
    max-width: 420px
}

.max-w-\[calc\(100\%-48px\)\] {
    max-width: calc(100% - 48px)
}

.max-w-\[80px\] {
    max-width: 80px
}

.max-w-4xl {
    max-width: 56rem
}

.flex-1 {
    flex: 1 1 0%
}

.shrink-0 {
    flex-shrink: 0
}

.flex-grow,
.grow {
    flex-grow: 1
}

.-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-\[150\%\] {
    --tw-translate-y: 150%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-10 {
    --tw-translate-y: -2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-0 {
    --tw-translate-y: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-64 {
    --tw-translate-y: 16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-full {
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-x-0 {
    --tw-translate-x: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-x-0 {
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@-webkit-keyframes spin {
    to {
        transform: rotate(360deg)
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg)
    }
}

.animate-spin {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite
}

@-webkit-keyframes bounce {

    0%,
    to {
        transform: translateY(-25%);
        -webkit-animation-timing-function: cubic-bezier(.8, 0, 1, 1);
        animation-timing-function: cubic-bezier(.8, 0, 1, 1)
    }

    50% {
        transform: none;
        -webkit-animation-timing-function: cubic-bezier(0, 0, .2, 1);
        animation-timing-function: cubic-bezier(0, 0, .2, 1)
    }
}

@keyframes bounce {

    0%,
    to {
        transform: translateY(-25%);
        -webkit-animation-timing-function: cubic-bezier(.8, 0, 1, 1);
        animation-timing-function: cubic-bezier(.8, 0, 1, 1)
    }

    50% {
        transform: none;
        -webkit-animation-timing-function: cubic-bezier(0, 0, .2, 1);
        animation-timing-function: cubic-bezier(0, 0, .2, 1)
    }
}

.animate-bounce {
    -webkit-animation: bounce 1s infinite;
    animation: bounce 1s infinite
}

@-webkit-keyframes pulse {
    50% {
        opacity: .5
    }
}

@keyframes pulse {
    50% {
        opacity: .5
    }
}

.animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
    animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite
}

.cursor-pointer {
    cursor: pointer
}

.select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.snap-x {
    -ms-scroll-snap-type: x var(--tw-scroll-snap-strictness);
    scroll-snap-type: x var(--tw-scroll-snap-strictness)
}

.snap-mandatory {
    --tw-scroll-snap-strictness: mandatory
}

.snap-center {
    scroll-snap-align: center
}

.auto-cols-\[70\%\] {
    grid-auto-columns: 70%
}

.auto-cols-\[100\%\] {
    grid-auto-columns: 100%
}

.auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
}

.auto-cols-\[90\%\] {
    grid-auto-columns: 90%
}

.grid-flow-col {
    grid-auto-flow: column
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
}

.grid-cols-\[2fr\,80px\,80px\,30px\,50px\,70px\] {
    grid-template-columns: 2fr 80px 80px 30px 50px 70px
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-cols-\[80px\,2fr\,85px\,130px\,80px\,60px\,40px\] {
    grid-template-columns: 80px 2fr 85px 130px 80px 60px 40px
}

.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
}

.grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
}

.grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
}

.grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
}

.flex-col {
    flex-direction: column
}

.flex-wrap {
    flex-wrap: wrap
}

.flex-nowrap {
    flex-wrap: nowrap
}

.items-start {
    align-items: flex-start
}

.items-end {
    align-items: flex-end
}

.items-center {
    align-items: center
}

.justify-start {
    justify-content: flex-start
}

.justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.gap-6 {
    gap: 1.5rem
}

.gap-3 {
    gap: .75rem
}

.gap-2 {
    gap: .5rem
}

.gap-4 {
    gap: 1rem
}

.gap-12 {
    gap: 3rem
}

.gap-0 {
    gap: 0px
}

.gap-1 {
    gap: .25rem
}

.gap-\[2px\] {
    gap: 2px
}

.gap-10 {
    gap: 2.5rem
}

.gap-y-10 {
    row-gap: 2.5rem
}

.gap-y-3 {
    row-gap: .75rem
}

.gap-x-2 {
    -moz-column-gap: .5rem;
    column-gap: .5rem
}

.gap-y-2 {
    row-gap: .5rem
}

.gap-x-6 {
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem
}

.gap-y-6 {
    row-gap: 1.5rem
}

.gap-y-1 {
    row-gap: .25rem
}

.gap-x-12 {
    -moz-column-gap: 3rem;
    column-gap: 3rem
}

.gap-y-5 {
    row-gap: 1.25rem
}

.gap-y-4 {
    row-gap: 1rem
}

.gap-x-16 {
    -moz-column-gap: 4rem;
    column-gap: 4rem
}

.gap-x-3 {
    -moz-column-gap: .75rem;
    column-gap: .75rem
}

.gap-y-0 {
    row-gap: 0px
}

.gap-x-1 {
    -moz-column-gap: .25rem;
    column-gap: .25rem
}

.gap-y-2\.5 {
    row-gap: .625rem
}

.gap-x-4 {
    -moz-column-gap: 1rem;
    column-gap: 1rem
}

.overflow-hidden {
    overflow: hidden
}

.overflow-x-auto {
    overflow-x: auto
}

.overflow-y-auto {
    overflow-y: auto
}

.overflow-x-hidden {
    overflow-x: hidden
}

.overflow-x-scroll {
    overflow-x: scroll
}

.overscroll-contain {
    -ms-scroll-chaining: none;
    overscroll-behavior: contain
}

.scroll-smooth {
    scroll-behavior: smooth
}

.whitespace-nowrap {
    white-space: nowrap
}

.whitespace-pre {
    white-space: pre
}

.break-words {
    overflow-wrap: break-word
}

.rounded-full {
    border-radius: 9999px
}

.rounded-md {
    border-radius: .375rem
}

.rounded-lg {
    border-radius: .5rem
}

.rounded-2xl {
    border-radius: 1rem
}

.rounded-xl {
    border-radius: .75rem
}

.rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
}

.rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

.border-2 {
    border-width: 2px
}

.border-4 {
    border-width: 4px
}

.border-\[1px\] {
    border-width: 1px
}

.border-\[2px\] {
    border-width: 2px
}

.border-0 {
    border-width: 0px
}

.border-b-2 {
    border-bottom-width: 2px
}

.border-t-\[1px\] {
    border-top-width: 1px
}

.border-b-\[1px\] {
    border-bottom-width: 1px
}

.border-r-\[1px\] {
    border-right-width: 1px
}

.border-l-0 {
    border-left-width: 0px
}

.border-r-0 {
    border-right-width: 0px
}

.border-t-0 {
    border-top-width: 0px
}

.border-t-2 {
    border-top-width: 2px
}

.border-none {
    border-style: none
}

.border-green04 {
    --tw-border-opacity: 1;
    border-color: rgb(19 44 46 / var(--tw-border-opacity))
}

.border-yellow02 {
    --tw-border-opacity: 1;
    border-color: rgb(247 203 94 / var(--tw-border-opacity))
}

.border-gray03 {
    border-color: #c7c7c7
}

.border-green02 {
    --tw-border-opacity: 1;
    border-color: rgb(40 125 59 / var(--tw-border-opacity))
}

.border-black01 {
    border-color: #101010
}

.border-red01 {
    --tw-border-opacity: 1;
    border-color: rgb(214 90 0 / var(--tw-border-opacity))
}

.border-green03 {
    --tw-border-opacity: 1;
    border-color: rgb(90 170 107 / var(--tw-border-opacity))
}

.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.border-gray02 {
    border-color: #666
}

.border-neutral02 {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 244 / var(--tw-border-opacity))
}

.border-neutral01 {
    --tw-border-opacity: 1;
    border-color: rgb(240 237 230 / var(--tw-border-opacity))
}

.border-green05 {
    --tw-border-opacity: 1;
    border-color: rgb(198 232 129 / var(--tw-border-opacity))
}

.border-gray04 {
    border-color: #e5e5e5
}

.border-x-gray03 {
    border-left-color: #c7c7c7;
    border-right-color: #c7c7c7
}

.border-b-green04 {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(19 44 46 / var(--tw-border-opacity))
}

.border-t-gray03 {
    border-top-color: #c7c7c7
}

.border-b-gray03 {
    border-bottom-color: #c7c7c7
}

.border-b-neutral01 {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(240 237 230 / var(--tw-border-opacity))
}

.border-r-neutral01 {
    --tw-border-opacity: 1;
    border-right-color: rgb(240 237 230 / var(--tw-border-opacity))
}

.border-t-gray04 {
    border-top-color: #e5e5e5
}

.border-b-green02 {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(40 125 59 / var(--tw-border-opacity))
}

.border-b-yellow02 {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(247 203 94 / var(--tw-border-opacity))
}

.border-b-neutral02 {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(250 250 244 / var(--tw-border-opacity))
}

.border-r-gray04 {
    border-right-color: #e5e5e5
}

.border-b-gray04 {
    border-bottom-color: #e5e5e5
}

.border-b-slate-200 {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(226 232 240 / var(--tw-border-opacity))
}

.border-b-black {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(0 0 0 / var(--tw-border-opacity))
}

.bg-neutral02 {
    --tw-bg-opacity: 1;
    /* background-color: rgb(255 255 255 / var(--tw-bg-opacity)) */
}

.bg-yellow02 {
    --tw-bg-opacity: 1;
    background-color: rgb(247 203 94 / var(--tw-bg-opacity))
}

.bg-neutral01 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 237 230 / var(--tw-bg-opacity))
}

.bg-red01 {
    --tw-bg-opacity: 1;
    background-color: rgb(214 90 0 / var(--tw-bg-opacity))
}

.bg-transparent {
    background-color: transparent
}

.bg-green04 {
    --tw-bg-opacity: 1;
    background-color: rgb(19 44 46 / var(--tw-bg-opacity))
}

.bg-yellow03 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 243 212 / var(--tw-bg-opacity))
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-green02 {
    --tw-bg-opacity: 1;
    background-color: rgb(40 125 59 / var(--tw-bg-opacity))
}

.bg-gray02 {
    background-color: #666
}

.bg-green03 {
    --tw-bg-opacity: 1;
    background-color: rgb(90 170 107 / var(--tw-bg-opacity))
}

.bg-gray03 {
    background-color: #c7c7c7
}

.bg-gray04 {
    background-color: #e5e5e5
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-green06 {
    --tw-bg-opacity: 1;
    background-color: rgb(0 24 26 / var(--tw-bg-opacity))
}

.bg-violet01 {
    --tw-bg-opacity: 1;
    background-color: rgb(103 33 76 / var(--tw-bg-opacity))
}

.bg-yellow05 {
    --tw-bg-opacity: 1;
    background-color: rgb(242 221 134 / var(--tw-bg-opacity))
}

.bg-\[\#606769\] {
    --tw-bg-opacity: 1;
    background-color: rgb(96 103 105 / var(--tw-bg-opacity))
}

.bg-\[rgba\(0\,0\,0\,\.20\)\] {
    background-color: #0003
}

.bg-yellow01 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 213 112 / var(--tw-bg-opacity))
}

.bg-blue01,
.bg-\[\#d3daec\] {
    --tw-bg-opacity: 1;
    background-color: rgb(211 218 236 / var(--tw-bg-opacity))
}

.bg-slate-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity))
}

.bg-collections-rise-reserve {
    --tw-bg-opacity: 1;
    background-color: rgb(109 76 142 / var(--tw-bg-opacity))
}

.bg-collections-variety-packs {
    --tw-bg-opacity: 1;
    background-color: rgb(103 33 76 / var(--tw-bg-opacity))
}

.bg-collections-all {
    --tw-bg-opacity: 1;
    background-color: rgb(19 44 46 / var(--tw-bg-opacity))
}

.bg-collections-big-greens {
    --tw-bg-opacity: 1;
    background-color: rgb(0 137 107 / var(--tw-bg-opacity))
}

.bg-collections-little-greens {
    --tw-bg-opacity: 1;
    background-color: rgb(32 171 75 / var(--tw-bg-opacity))
}

.bg-collections-herbs {
    --tw-bg-opacity: 1;
    background-color: rgb(80 222 127 / var(--tw-bg-opacity))
}

.bg-collections-flowers {
    --tw-bg-opacity: 1;
    background-color: rgb(197 102 131 / var(--tw-bg-opacity))
}

.bg-collections-vines {
    --tw-bg-opacity: 1;
    background-color: rgb(120 145 234 / var(--tw-bg-opacity))
}

.bg-collections-roots {
    --tw-bg-opacity: 1;
    background-color: rgb(117 104 79 / var(--tw-bg-opacity))
}

.bg-collections-mushrooms {
    --tw-bg-opacity: 1;
    background-color: rgb(231 231 224 / var(--tw-bg-opacity))
}

.bg-collections-house-plants {
    --tw-bg-opacity: 1;
    background-color: rgb(249 213 112 / var(--tw-bg-opacity))
}

.bg-collections-fruits {
    --tw-bg-opacity: 1;
    background-color: rgb(247 137 62 / var(--tw-bg-opacity))
}

.bg-collections-microgreens {
    --tw-bg-opacity: 1;
    background-color: rgb(89 9 45 / var(--tw-bg-opacity))
}

.bg-opacity-90 {
    --tw-bg-opacity: .9
}

.bg-opacity-20 {
    --tw-bg-opacity: .2
}

.bg-opacity-40 {
    --tw-bg-opacity: .4
}

.bg-opacity-60 {
    --tw-bg-opacity: .6
}

.bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.bg-seedblob {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ5IiBoZWlnaHQ9IjE1MyIgdmlld0JveD0iMCAwIDE0OSAxNTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNzIuMDcyMSAxMS4wMDg0Qzg4LjExMjUgMTEuMjc0NSAxMDIuNjIgMTkuMTA4NCAxMTQuMTYyIDMwLjE5NjhDMTI1Ljk0NSA0MS41MTU1IDEzNC4yNTYgNTUuODQ3MSAxMzUuNjUgNzIuMDg1NEMxMzcuMTc3IDg5Ljg3NyAxMzMuODk3IDEwOC40MSAxMjIuMDI2IDEyMS44QzEwOS41MDIgMTM1LjkyNiA5MC45MjEyIDE0NS41MjkgNzIuMDcyMSAxNDMuNzk5QzU0LjI4NzcgMTQyLjE2NiA0MS43NjEzIDEyNy4yNDMgMzAuNTU3MSAxMTMuNDAxQzIwLjg0MyAxMDEuNCAxNC4zODg0IDg3LjQ5MTYgMTQuMDIzOSA3Mi4wODU0QzEzLjY0OTcgNTYuMjY3OSAxNy42MzAzIDQwLjI3MTUgMjguNTU1IDI4Ljc3NjlDMzkuNzU1NCAxNi45OTIxIDU1Ljc3NDkgMTAuNzM4MSA3Mi4wNzIxIDExLjAwODRaIiBmaWxsPSIjRjJERDg2Ii8+Cjwvc3ZnPgo=)
}

.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops))
}

.bg-\[url\(\'data\:image\/png\;base64\,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW\/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAARSURBVHgBY2BAB87OzmoYggAShwDwS\+pMggAAAABJRU5ErkJggg\=\=\'\)\] {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAARSURBVHgBY2BAB87OzmoYggAShwDwS+pMggAAAABJRU5ErkJggg==)
}

.bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops))
}

.from-neutral02 {
    --tw-gradient-from: #FAFAF4;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(250 250 244 / 0))
}

.from-neutral01 {
    --tw-gradient-from: #F0EDE6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(240 237 230 / 0))
}

.to-transparent {
    --tw-gradient-to: transparent
}

.bg-cover {
    background-size: cover
}

.bg-center {
    background-position: center
}

.bg-right {
    background-position: right
}

.bg-repeat {
    background-repeat: repeat
}

.fill-neutral02 {
    fill: #fafaf4
}

.fill-green04 {
    fill: #132c2e
}

.fill-violet01 {
    fill: #67214c
}

.fill-yellow02 {
    fill: #f7cb5e
}

.fill-green06 {
    fill: #00181a
}

.object-cover {
    -o-object-fit: cover;
    object-fit: cover
}

.p-6 {
    padding: 1.5rem
}

.p-3 {
    padding: .75rem
}

.p-2 {
    padding: .5rem
}

.p-4 {
    padding: 1rem
}

.p-10 {
    padding: 2.5rem
}

.p-1 {
    padding: .25rem
}

.p-8 {
    padding: 2rem
}

.p-0 {
    padding: 0
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.px-0 {
    padding-left: 0;
    padding-right: 0
}

.py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem
}

.py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
}

.py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem
}

.py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem
}

.py-10 {
    /* padding-top: 2.5rem; */
    padding-bottom: 2.5rem
}

.py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem
}

.px-3 {
    padding-left: .75rem;
    padding-right: .75rem
}

.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.px-2 {
    padding-left: .5rem;
    padding-right: .5rem
}

.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem
}

.py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem
}

.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
}

.py-\[18px\] {
    padding-top: 18px;
    padding-bottom: 18px
}

.py-\[12px\] {
    padding-top: 12px;
    padding-bottom: 12px
}

.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0
}

.px-1 {
    padding-left: .25rem;
    padding-right: .25rem
}

.pt-16 {
    padding-top: 4rem
}

.pb-24 {
    padding-bottom: 6rem
}

.pb-20 {
    padding-bottom: 5rem
}

.pt-10 {
    padding-top: 2.5rem
}

.pb-0\.5 {
    padding-bottom: .125rem
}

.pb-0 {
    padding-bottom: 0
}

.pl-5 {
    padding-left: 1.25rem
}

.pr-12 {
    padding-right: 3rem
}

.pt-14 {
    padding-top: 3.5rem
}

.pb-10 {
    padding-bottom: 2.5rem
}

.pl-0 {
    padding-left: 0
}

.pt-\[2px\] {
    padding-top: 2px
}

.pt-\[100\%\] {
    padding-top: 100%
}

.pt-6 {
    padding-top: 1.5rem
}

.pb-6 {
    padding-bottom: 1.5rem
}

.pt-48 {
    padding-top: 12rem
}

.pt-4 {
    padding-top: 1rem
}

.pr-6 {
    padding-right: 1.5rem
}

.pt-20 {
    padding-top: 5rem
}

.pl-10 {
    padding-left: 2.5rem
}

.pr-4 {
    padding-right: 1rem
}

.pl-1 {
    padding-left: .25rem
}

.pr-5 {
    padding-right: 1.25rem
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.text-right {
    text-align: right
}

.align-middle {
    vertical-align: middle
}

.font-display {
    font-family: Maven Pro
}

.font-sans {
    font-family: Inter
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
}

.text-xs {
    font-size: .75rem;
    line-height: 1rem
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}

.font-semibold {
    font-weight: 600
}

.font-normal {
    font-weight: 400
}

.font-medium {
    font-weight: 500
}

.font-bold {
    font-weight: 700
}

.font-light {
    font-weight: 300
}

.font-thin {
    font-weight: 100
}

.uppercase {
    text-transform: uppercase
}

.capitalize {
    text-transform: capitalize
}

.leading-tight {
    line-height: 1.25
}

.leading-8 {
    line-height: 2rem
}

.tracking-wide {
    letter-spacing: .025em
}

.text-green04 {
    --tw-text-opacity: 1;
    color: rgb(19 44 46 / var(--tw-text-opacity))
}

.text-neutral02 {
    --tw-text-opacity: 1;
    color: rgb(250 250 244 / var(--tw-text-opacity))
}

.text-gray02 {
    color: #666
}

.text-green02 {
    --tw-text-opacity: 1;
    color: rgb(40 125 59 / var(--tw-text-opacity))
}

.text-red01 {
    --tw-text-opacity: 1;
    color: rgb(214 90 0 / var(--tw-text-opacity))
}

.text-black01 {
    color: #101010
}

.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-green03 {
    --tw-text-opacity: 1;
    color: rgb(90 170 107 / var(--tw-text-opacity))
}

.text-green05 {
    --tw-text-opacity: 1;
    color: rgb(198 232 129 / var(--tw-text-opacity))
}

.text-neutral01 {
    --tw-text-opacity: 1;
    color: rgb(240 237 230 / var(--tw-text-opacity))
}

.text-gray03 {
    color: #c7c7c7
}

.text-green06 {
    --tw-text-opacity: 1;
    color: rgb(0 24 26 / var(--tw-text-opacity))
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-yellow02 {
    --tw-text-opacity: 1;
    color: rgb(247 203 94 / var(--tw-text-opacity))
}

.text-violet01,
.text-collections-variety-packs {
    --tw-text-opacity: 1;
    color: rgb(103 33 76 / var(--tw-text-opacity))
}

.text-collections-rise-reserve {
    --tw-text-opacity: 1;
    color: rgb(109 76 142 / var(--tw-text-opacity))
}

.text-collections-all {
    --tw-text-opacity: 1;
    color: rgb(19 44 46 / var(--tw-text-opacity))
}

.text-collections-big-greens {
    --tw-text-opacity: 1;
    color: rgb(0 137 107 / var(--tw-text-opacity))
}

.text-collections-little-greens {
    --tw-text-opacity: 1;
    color: rgb(32 171 75 / var(--tw-text-opacity))
}

.text-collections-herbs {
    --tw-text-opacity: 1;
    color: rgb(80 222 127 / var(--tw-text-opacity))
}

.text-collections-flowers {
    --tw-text-opacity: 1;
    color: rgb(197 102 131 / var(--tw-text-opacity))
}

.text-collections-vines {
    --tw-text-opacity: 1;
    color: rgb(120 145 234 / var(--tw-text-opacity))
}

.text-collections-roots {
    --tw-text-opacity: 1;
    color: rgb(117 104 79 / var(--tw-text-opacity))
}

.text-collections-mushrooms {
    --tw-text-opacity: 1;
    color: rgb(231 231 224 / var(--tw-text-opacity))
}

.text-collections-house-plants {
    --tw-text-opacity: 1;
    color: rgb(249 213 112 / var(--tw-text-opacity))
}

.text-collections-fruits {
    --tw-text-opacity: 1;
    color: rgb(247 137 62 / var(--tw-text-opacity))
}

.text-collections-microgreens {
    --tw-text-opacity: 1;
    color: rgb(89 9 45 / var(--tw-text-opacity))
}

.underline {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline
}

.line-through {
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through
}

.opacity-100 {
    opacity: 1
}

.opacity-0 {
    opacity: 0
}

.opacity-10 {
    opacity: .1
}

.opacity-50 {
    opacity: .5
}

.opacity-40 {
    opacity: .4
}

.opacity-80 {
    opacity: .8
}

.opacity-60 {
    opacity: .6
}

.opacity-70 {
    opacity: .7
}

.mix-blend-multiply {
    mix-blend-mode: multiply
}

.mix-blend-darken {
    mix-blend-mode: darken
}

.shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / .1), 0 8px 10px -6px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / .05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / .25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline {
    outline-style: solid
}

.ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.blur-xl {
    --tw-blur: blur(24px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.blur-\[0px\] {
    --tw-blur: blur(0px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.grayscale {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.transition {
    transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.delay-150 {
    transition-delay: .15s
}

.delay-300 {
    transition-delay: .3s
}

.duration-300 {
    transition-duration: .3s
}

.duration-700 {
    transition-duration: .7s
}

.duration-500 {
    transition-duration: .5s
}

.duration-1000 {
    transition-duration: 1s
}

.ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1)
}

.ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1)
}

:root {
    --primary: #742a2a
}

html {
    height: -moz-available;
    height: fill-available;
    height: -webkit-fill-available
}

body {
    min-height: 50vh;
    min-height: -moz-available;
    min-height: fill-available;
    min-height: -webkit-fill-available
}

html.zoom {
    zoom: 125%
}

html.desaturate {
    filter: grayscale(100)
}

html.invert {
    filter: invert(1)
}

html.easyread h1,
html.easyread h2,
html.easyread h3,
html.easyread h4,
html.easyread h5,
html.easyread h6,
html.easyread p,
html.easyread li,
html.easyread button,
html.easyread a html.easyread h1 *,
html.easyread h2 *,
html.easyread h3 *,
html.easyread h4 *,
html.easyread h5 *,
html.easyread h6 *,
html.easyread p *,
html.easyread li *,
html.easyread button *,
html.easyread a * {
    border-width: 2px !important;
    --tw-border-opacity: 1 !important;
    border-color: rgb(247 203 94 / var(--tw-border-opacity)) !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(19 44 46 / var(--tw-bg-opacity)) !important;
    --tw-text-opacity: 1 !important;
    color: rgb(240 237 230 / var(--tw-text-opacity)) !important
}

[x-cloak] {
    opacity: 0;
    visibility: hidden;
    position: absolute
}

img.loading {
    filter: opacity(0) blur(2px)
}

img {
    transition: all .1s ease-in;
    width: 100%;
    height: auto;
    filter: opacity(1) blur(0px)
}

.neutral.affirm-as-low-as .__affirm-logo-blue {
    --tw-text-opacity: 1 !important;
    color: rgb(250 250 244 / var(--tw-text-opacity)) !important
}

#article-content ol {
    list-style-type: none !important;
    padding-left: 0 !important
}

#article-content ol li {
    position: relative !important;
    padding-left: 0 !important
}

#article-content ol li strong {
    display: block !important;
    padding-left: 1.5rem !important;
    --tw-text-opacity: 1 !important;
    color: rgb(90 170 107 / var(--tw-text-opacity)) !important
}

#article-content ol li strong:before {
    content: "";
    position: absolute !important;
    top: .5rem !important;
    left: 0px !important;
    height: 1.25rem !important;
    width: 1.25rem !important;
    background-image: url(data:image/webp;base64,UklGRkYBAABXRUJQVlA4WAoAAAAQAAAAEwAAEwAAQUxQSJ4AAAABgBVJch2pIAjCQDAEQRCDHQhisMNgl8Edk4FgCIJgCP2hR8QyiIgJ4JfFI1o5q6k5jt5Sdm/dTt4ale3rvibTMLbXI5tSzvZ65ACmm60NdeamDtdkQ8Ey1Gj6gA0F4GVyytBfGQqgKcDVwW49+gJ0NShKoNz6Z04ZkKpAacyuBKgaxtaGbCI0fFWHgnVI2Zv3lIK9pZZZObYW4cYvAVZQOCCCAAAAUAQAnQEqFAAUAD6dPpxJpAlsBgIALATiWwAnTKEemWgNs7dnO81AR6bVAAD+21FcKpgs/sBkvjo8OJK/DyKRFUd2SJ4O0So8Faop7Ixx4BUgttv1EcPu0gwvZLr9c5xTE4ncs0vakMDT7BmAEIAihethtozmi/PMR/9t2t3yYbHwAA==) !important
}

iframe#launcher {
    display: none
}

#body_checkout img {
    width: auto;
    height: auto
}

#body_checkout button[type=submit] {
    --tw-bg-opacity: 1;
    background-color: rgb(40 125 59 / var(--tw-bg-opacity))
}

.placeholder\:font-medium::-moz-placeholder {
    font-weight: 500
}

.placeholder\:font-medium:-ms-input-placeholder {
    font-weight: 500
}

.placeholder\:font-medium::placeholder {
    font-weight: 500
}

.placeholder\:text-green04::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(19 44 46 / var(--tw-text-opacity))
}

.placeholder\:text-green04:-ms-input-placeholder {
    --tw-text-opacity: 1;
    color: rgb(19 44 46 / var(--tw-text-opacity))
}

.placeholder\:text-green04::placeholder {
    --tw-text-opacity: 1;
    color: rgb(19 44 46 / var(--tw-text-opacity))
}

.placeholder\:text-gray02::-moz-placeholder {
    color: #666
}

.placeholder\:text-gray02:-ms-input-placeholder {
    color: #666
}

.placeholder\:text-gray02::placeholder {
    color: #666
}

.first-of-type\:border-t-0:first-of-type {
    border-top-width: 0px
}

.default\:border-gray03:default {
    border-color: #c7c7c7
}

.invalid\:border-red01:invalid {
    --tw-border-opacity: 1;
    border-color: rgb(214 90 0 / var(--tw-border-opacity))
}

.invalid\:ring-red01:invalid {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(214 90 0 / var(--tw-ring-opacity))
}

.hover\:cursor-pointer:hover {
    cursor: pointer
}

.hover\:border-green06:hover {
    --tw-border-opacity: 1;
    border-color: rgb(0 24 26 / var(--tw-border-opacity))
}

.hover\:border-green04:hover {
    --tw-border-opacity: 1;
    border-color: rgb(19 44 46 / var(--tw-border-opacity))
}

.hover\:border-green01:hover {
    --tw-border-opacity: 1;
    border-color: rgb(10 95 29 / var(--tw-border-opacity))
}

.hover\:border-yellow01:hover {
    --tw-border-opacity: 1;
    border-color: rgb(249 213 112 / var(--tw-border-opacity))
}

.hover\:border-green02:hover {
    --tw-border-opacity: 1;
    border-color: rgb(40 125 59 / var(--tw-border-opacity))
}

.hover\:border-green03:hover {
    --tw-border-opacity: 1;
    border-color: rgb(90 170 107 / var(--tw-border-opacity))
}

.hover\:border-red01:hover {
    --tw-border-opacity: 1;
    border-color: rgb(214 90 0 / var(--tw-border-opacity))
}

.hover\:border-b-green01:hover {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(10 95 29 / var(--tw-border-opacity))
}

.hover\:border-b-green06:hover {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(0 24 26 / var(--tw-border-opacity))
}

.hover\:border-b-yellow01:hover {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(249 213 112 / var(--tw-border-opacity))
}

.hover\:border-b-neutral02:hover {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(250 250 244 / var(--tw-border-opacity))
}

.hover\:bg-green04:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(19 44 46 / var(--tw-bg-opacity))
}

.hover\:bg-green06:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 24 26 / var(--tw-bg-opacity))
}

.hover\:bg-green01:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(10 95 29 / var(--tw-bg-opacity))
}

.hover\:bg-black01:hover {
    background-color: #101010
}

.hover\:bg-neutral01:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 237 230 / var(--tw-bg-opacity))
}

.hover\:bg-yellow01:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 213 112 / var(--tw-bg-opacity))
}

.hover\:bg-green02:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(40 125 59 / var(--tw-bg-opacity))
}

.hover\:bg-neutral02:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 244 / var(--tw-bg-opacity))
}

.hover\:bg-green03:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(90 170 107 / var(--tw-bg-opacity))
}

.hover\:bg-red01:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(214 90 0 / var(--tw-bg-opacity))
}

.hover\:fill-green06:hover {
    fill: #00181a
}

.hover\:text-neutral02:hover {
    --tw-text-opacity: 1;
    color: rgb(250 250 244 / var(--tw-text-opacity))
}

.hover\:text-green06:hover {
    --tw-text-opacity: 1;
    color: rgb(0 24 26 / var(--tw-text-opacity))
}

.hover\:text-gray01:hover {
    color: #423f41
}

.hover\:text-green01:hover {
    --tw-text-opacity: 1;
    color: rgb(10 95 29 / var(--tw-text-opacity))
}

.hover\:text-green03:hover {
    --tw-text-opacity: 1;
    color: rgb(90 170 107 / var(--tw-text-opacity))
}

.hover\:text-neutral01:hover {
    --tw-text-opacity: 1;
    color: rgb(240 237 230 / var(--tw-text-opacity))
}

.hover\:text-yellow01:hover {
    --tw-text-opacity: 1;
    color: rgb(249 213 112 / var(--tw-text-opacity))
}

.hover\:text-green04:hover {
    --tw-text-opacity: 1;
    color: rgb(19 44 46 / var(--tw-text-opacity))
}

.hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity))
}

.hover\:text-violet01:hover {
    --tw-text-opacity: 1;
    color: rgb(103 33 76 / var(--tw-text-opacity))
}

.hover\:text-yellow02:hover {
    --tw-text-opacity: 1;
    color: rgb(247 203 94 / var(--tw-text-opacity))
}

.hover\:text-green05:hover {
    --tw-text-opacity: 1;
    color: rgb(198 232 129 / var(--tw-text-opacity))
}

.hover\:text-collections-variety-packs:hover {
    --tw-text-opacity: 1;
    color: rgb(103 33 76 / var(--tw-text-opacity))
}

.hover\:text-collections-rise-reserve:hover {
    --tw-text-opacity: 1;
    color: rgb(109 76 142 / var(--tw-text-opacity))
}

.hover\:text-collections-all:hover {
    --tw-text-opacity: 1;
    color: rgb(19 44 46 / var(--tw-text-opacity))
}

.hover\:text-collections-big-greens:hover {
    --tw-text-opacity: 1;
    color: rgb(0 137 107 / var(--tw-text-opacity))
}

.hover\:text-collections-little-greens:hover {
    --tw-text-opacity: 1;
    color: rgb(32 171 75 / var(--tw-text-opacity))
}

.hover\:text-collections-herbs:hover {
    --tw-text-opacity: 1;
    color: rgb(80 222 127 / var(--tw-text-opacity))
}

.hover\:text-collections-flowers:hover {
    --tw-text-opacity: 1;
    color: rgb(197 102 131 / var(--tw-text-opacity))
}

.hover\:text-collections-vines:hover {
    --tw-text-opacity: 1;
    color: rgb(120 145 234 / var(--tw-text-opacity))
}

.hover\:text-collections-roots:hover {
    --tw-text-opacity: 1;
    color: rgb(117 104 79 / var(--tw-text-opacity))
}

.hover\:text-collections-mushrooms:hover {
    --tw-text-opacity: 1;
    color: rgb(231 231 224 / var(--tw-text-opacity))
}

.hover\:text-collections-house-plants:hover {
    --tw-text-opacity: 1;
    color: rgb(249 213 112 / var(--tw-text-opacity))
}

.hover\:text-collections-fruits:hover {
    --tw-text-opacity: 1;
    color: rgb(247 137 62 / var(--tw-text-opacity))
}

.hover\:text-collections-microgreens:hover {
    --tw-text-opacity: 1;
    color: rgb(89 9 45 / var(--tw-text-opacity))
}

.hover\:text-green02:hover {
    --tw-text-opacity: 1;
    color: rgb(40 125 59 / var(--tw-text-opacity))
}

.hover\:underline:hover {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline
}

.hover\:opacity-100:hover {
    opacity: 1
}

.hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / .1), 0 8px 10px -6px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:border-0:focus {
    border-width: 0px
}

.focus\:border-b-2:focus {
    border-bottom-width: 2px
}

.focus\:border-gray01:focus {
    border-color: #423f41
}

.focus\:border-gray02:focus {
    border-color: #666
}

.focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:shadow-gray02:focus {
    --tw-shadow-color: rgba(102, 102, 102, 1);
    --tw-shadow: var(--tw-shadow-colored)
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-gray02:focus {
    --tw-ring-color: rgba(102, 102, 102, 1)
}

.focus\:ring-transparent:focus {
    --tw-ring-color: transparent
}

.focus\:invalid\:border-red01:invalid:focus {
    --tw-border-opacity: 1;
    border-color: rgb(214 90 0 / var(--tw-border-opacity))
}

.focus\:invalid\:ring-red01:invalid:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(214 90 0 / var(--tw-ring-opacity))
}

.disabled\:cursor-not-allowed:disabled {
    cursor: not-allowed
}

.disabled\:border-gray02:disabled {
    border-color: #666
}

.disabled\:border-green03:disabled {
    --tw-border-opacity: 1;
    border-color: rgb(90 170 107 / var(--tw-border-opacity))
}

.disabled\:bg-gray02:disabled {
    background-color: #666
}

.disabled\:bg-green03:disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(90 170 107 / var(--tw-bg-opacity))
}

.disabled\:opacity-50:disabled {
    opacity: .5
}

.disabled\:opacity-60:disabled {
    opacity: .6
}

.group:hover .group-hover\:visible {
    visibility: visible
}

.group:hover .group-hover\:block {
    display: block
}

.group:hover .group-hover\:rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.group:hover .group-hover\:text-green03 {
    --tw-text-opacity: 1;
    color: rgb(90 170 107 / var(--tw-text-opacity))
}

.group:hover .group-hover\:opacity-100 {
    opacity: 1
}

.group:hover .group-hover\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.group:hover .group-hover\:duration-500 {
    transition-duration: .5s
}

.peer:checked~.peer-checked\:border-green03 {
    --tw-border-opacity: 1;
    border-color: rgb(90 170 107 / var(--tw-border-opacity))
}

.peer:checked~.peer-checked\:border-gray03 {
    border-color: #c7c7c7
}

.peer:checked~.peer-checked\:bg-green03 {
    --tw-bg-opacity: 1;
    background-color: rgb(90 170 107 / var(--tw-bg-opacity))
}

.peer:checked~.peer-checked\:bg-neutral02 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 244 / var(--tw-bg-opacity))
}

.peer:checked~.peer-checked\:bg-opacity-5 {
    --tw-bg-opacity: .05
}

.peer:invalid~.peer-invalid\:visible {
    visibility: visible
}

.peer:disabled~.peer-disabled\:bg-gray04 {
    background-color: #e5e5e5
}

.peer:disabled~.peer-disabled\:bg-opacity-5 {
    --tw-bg-opacity: .05
}

.peer:disabled~.peer-disabled\:opacity-30 {
    opacity: .3
}

.prose-h2\:mt-0 :is(:where(h2):not(:where([class~="not-prose"] *))) {
    margin-top: 0
}

[data-theme=darken] .darken\:border-white,
[data-theme=darken][detach].darken\:border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

[data-theme=darken] .darken\:border-green04,
[data-theme=darken][detach].darken\:border-green04 {
    --tw-border-opacity: 1;
    border-color: rgb(19 44 46 / var(--tw-border-opacity))
}

[data-theme=darken] .darken\:bg-green04,
[data-theme=darken][detach].darken\:bg-green04 {
    --tw-bg-opacity: 1;
    background-color: rgb(19 44 46 / var(--tw-bg-opacity))
}

[data-theme=darken] .darken\:fill-neutral02,
[data-theme=darken][detach].darken\:fill-neutral02 {
    fill: #fafaf4
}

[data-theme=darken] .darken\:text-neutral02,
[data-theme=darken][detach].darken\:text-neutral02 {
    --tw-text-opacity: 1;
    color: rgb(250 250 244 / var(--tw-text-opacity))
}

[data-theme=darken] .darken\:text-yellow02,
[data-theme=darken][detach].darken\:text-yellow02 {
    --tw-text-opacity: 1;
    color: rgb(247 203 94 / var(--tw-text-opacity))
}

[data-theme=lime] .lime\:border-white,
[data-theme=lime].lime\:border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

[data-theme=lime] .lime\:border-green05,
[data-theme=lime].lime\:border-green05 {
    --tw-border-opacity: 1;
    border-color: rgb(198 232 129 / var(--tw-border-opacity))
}

[data-theme=lime] .lime\:bg-green05,
[data-theme=lime].lime\:bg-green05 {
    --tw-bg-opacity: 1;
    background-color: rgb(198 232 129 / var(--tw-bg-opacity))
}

[data-theme=lime] .lime\:bg-neutral01,
[data-theme=lime].lime\:bg-neutral01 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 237 230 / var(--tw-bg-opacity))
}

[data-theme=lime] .lime\:fill-green04,
[data-theme=lime].lime\:fill-green04 {
    fill: #132c2e
}

[data-theme=lime] .lime\:text-green04,
[data-theme=lime].lime\:text-green04 {
    --tw-text-opacity: 1;
    color: rgb(19 44 46 / var(--tw-text-opacity))
}

[data-theme=lime] .lime\:text-green03,
[data-theme=lime].lime\:text-green03 {
    --tw-text-opacity: 1;
    color: rgb(90 170 107 / var(--tw-text-opacity))
}

[data-theme=rise] .rise\:border-white,
[data-theme=rise].rise\:border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

[data-theme=rise] .rise\:border-green03,
[data-theme=rise].rise\:border-green03 {
    --tw-border-opacity: 1;
    border-color: rgb(90 170 107 / var(--tw-border-opacity))
}

[data-theme=rise] .rise\:bg-green03,
[data-theme=rise].rise\:bg-green03 {
    --tw-bg-opacity: 1;
    background-color: rgb(90 170 107 / var(--tw-bg-opacity))
}

[data-theme=rise] .rise\:bg-neutral02,
[data-theme=rise].rise\:bg-neutral02 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 244 / var(--tw-bg-opacity))
}

[data-theme=rise] .rise\:from-green02,
[data-theme=rise].rise\:from-green02 {
    --tw-gradient-from: #287D3B;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(40 125 59 / 0))
}

[data-theme=rise] .rise\:to-green03,
[data-theme=rise].rise\:to-green03 {
    --tw-gradient-to: #5AAA6B
}

[data-theme=rise] .rise\:fill-green04,
[data-theme=rise].rise\:fill-green04 {
    fill: #132c2e
}

[data-theme=rise] .rise\:text-neutral02,
[data-theme=rise].rise\:text-neutral02 {
    --tw-text-opacity: 1;
    color: rgb(250 250 244 / var(--tw-text-opacity))
}

[data-theme=rise] .rise\:text-green04,
[data-theme=rise].rise\:text-green04 {
    --tw-text-opacity: 1;
    color: rgb(19 44 46 / var(--tw-text-opacity))
}

[data-theme=rise] .rise\:text-green03,
[data-theme=rise].rise\:text-green03 {
    --tw-text-opacity: 1;
    color: rgb(90 170 107 / var(--tw-text-opacity))
}

[data-theme=neutral] .neutral\:border-white,
[data-theme=neutral].neutral\:border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

[data-theme=neutral] .neutral\:border-neutral02,
[data-theme=neutral].neutral\:border-neutral02 {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 244 / var(--tw-border-opacity))
}

[data-theme=neutral] .neutral\:bg-neutral02,
[data-theme=neutral].neutral\:bg-neutral02 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 244 / var(--tw-bg-opacity))
}

[data-theme=neutral] .neutral\:bg-neutral01,
[data-theme=neutral].neutral\:bg-neutral01 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 237 230 / var(--tw-bg-opacity))
}

[data-theme=neutral] .neutral\:fill-green04,
[data-theme=neutral].neutral\:fill-green04 {
    fill: #132c2e
}

[data-theme=neutral] .neutral\:text-green04,
[data-theme=neutral].neutral\:text-green04 {
    --tw-text-opacity: 1;
    color: rgb(19 44 46 / var(--tw-text-opacity))
}

[data-theme=neutral] .neutral\:text-green03,
[data-theme=neutral].neutral\:text-green03 {
    --tw-text-opacity: 1;
    color: rgb(90 170 107 / var(--tw-text-opacity))
}

[data-theme=neutral] .neutral\:text-gray02,
[data-theme=neutral].neutral\:text-gray02 {
    color: #666
}

[data-theme=alabaster] .alabaster\:border-white,
[data-theme=alabaster].alabaster\:border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

[data-theme=alabaster] .alabaster\:border-neutral01,
[data-theme=alabaster].alabaster\:border-neutral01 {
    --tw-border-opacity: 1;
    border-color: rgb(240 237 230 / var(--tw-border-opacity))
}

[data-theme=alabaster] .alabaster\:bg-neutral01,
[data-theme=alabaster].alabaster\:bg-neutral01 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 237 230 / var(--tw-bg-opacity))
}

[data-theme=alabaster] .alabaster\:bg-neutral02,
[data-theme=alabaster].alabaster\:bg-neutral02 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 244 / var(--tw-bg-opacity))
}

[data-theme=alabaster] .alabaster\:fill-green04,
[data-theme=alabaster].alabaster\:fill-green04 {
    fill: #132c2e
}

[data-theme=alabaster] .alabaster\:text-green04,
[data-theme=alabaster].alabaster\:text-green04 {
    --tw-text-opacity: 1;
    color: rgb(19 44 46 / var(--tw-text-opacity))
}

[data-theme=alabaster] .alabaster\:text-green03,
[data-theme=alabaster].alabaster\:text-green03 {
    --tw-text-opacity: 1;
    color: rgb(90 170 107 / var(--tw-text-opacity))
}

[data-theme=alabaster] .alabaster\:text-gray02,
[data-theme=alabaster].alabaster\:text-gray02 {
    color: #666
}

[data-theme=yellow] .yellow\:bg-yellow05,
[data-theme=yellow].yellow\:bg-yellow05 {
    --tw-bg-opacity: 1;
    background-color: rgb(242 221 134 / var(--tw-bg-opacity))
}

[data-theme=yellow] .yellow\:bg-neutral02,
[data-theme=yellow].yellow\:bg-neutral02 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 244 / var(--tw-bg-opacity))
}

[data-theme=yellow] .yellow\:text-green04,
[data-theme=yellow].yellow\:text-green04 {
    --tw-text-opacity: 1;
    color: rgb(19 44 46 / var(--tw-text-opacity))
}

@media (min-width: 375px) {
    .xs\:absolute {
        position: absolute
    }

    .xs\:top-6 {
        top: 1.5rem
    }

    .xs\:left-1\/2 {
        left: 50%
    }

    .xs\:order-1 {
        order: 1
    }

    .xs\:order-2 {
        order: 2
    }

    .xs\:mt-32 {
        margin-top: 8rem
    }

    .xs\:mt-0 {
        margin-top: 0
    }

    .xs\:w-24 {
        width: 6rem
    }

    .xs\:w-32 {
        width: 8rem
    }

    .xs\:w-full {
        width: 100%
    }

    .xs\:max-w-sm {
        max-width: 24rem
    }

    .xs\:-translate-x-1\/2 {
        --tw-translate-x: -50%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .xs\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .xs\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .xs\:grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr))
    }

    .xs\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .xs\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr))
    }

    .xs\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr))
    }

    .xs\:flex-row {
        flex-direction: row
    }

    .xs\:rounded-lg {
        border-radius: .5rem
    }

    .xs\:border-2 {
        border-width: 2px
    }

    .xs\:border-neutral01 {
        --tw-border-opacity: 1;
        border-color: rgb(240 237 230 / var(--tw-border-opacity))
    }

    .xs\:p-6 {
        padding: 1.5rem
    }

    .xs\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .xs\:pl-2 {
        padding-left: .5rem
    }
}

@media (min-width: 640px) {
    .sm\:container {
        width: 100%
    }

    @media (min-width: 375px) {
        .sm\:container {
            max-width: 375px
        }
    }

    @media (min-width: 640px) {
        .sm\:container {
            max-width: 640px
        }
    }

    @media (min-width: 768px) {
        .sm\:container {
            max-width: 768px
        }
    }

    @media (min-width: 1024px) {
        .sm\:container {
            max-width: 1024px
        }
    }

    @media (min-width: 1280px) {
        .sm\:container {
            max-width: 1280px
        }
    }

    @media (min-width: 1536px) {
        .sm\:container {
            max-width: 1536px
        }
    }

    .sm\:relative {
        position: relative
    }

    .sm\:top-0 {
        top: 0px
    }

    .sm\:right-0 {
        right: 0px
    }

    .sm\:top-16 {
        top: 4rem
    }

    .sm\:-left-\[8\.5rem\] {
        left: -8.5rem
    }

    .sm\:-top-24 {
        top: -6rem
    }

    .sm\:left-0 {
        left: 0px
    }

    .sm\:top-20 {
        top: 5rem
    }

    .sm\:-order-1 {
        order: -1
    }

    .sm\:col-span-8 {
        grid-column: span 8 / span 8
    }

    .sm\:col-span-6 {
        grid-column: span 6 / span 6
    }

    .sm\:col-span-3 {
        grid-column: span 3 / span 3
    }

    .sm\:col-span-12 {
        grid-column: span 12 / span 12
    }

    .sm\:col-span-4 {
        grid-column: span 4 / span 4
    }

    .sm\:col-start-3 {
        grid-column-start: 3
    }

    .sm\:col-start-4 {
        grid-column-start: 4
    }

    .sm\:col-start-9 {
        grid-column-start: 9
    }

    .sm\:col-start-5 {
        grid-column-start: 5
    }

    .sm\:col-start-1 {
        grid-column-start: 1
    }

    .sm\:col-start-8 {
        grid-column-start: 8
    }

    .sm\:row-span-7 {
        grid-row: span 7 / span 7
    }

    .sm\:row-span-8 {
        grid-row: span 8 / span 8
    }

    .sm\:row-span-9 {
        grid-row: span 9 / span 9
    }

    .sm\:row-span-10 {
        grid-row: span 10 / span 10
    }

    .sm\:row-span-11 {
        grid-row: span 11 / span 11
    }

    .sm\:row-span-12 {
        grid-row: span 12 / span 12
    }

    .sm\:row-start-1 {
        grid-row-start: 1
    }

    .sm\:mx-auto {
        margin-left: auto;
        margin-right: auto
    }

    .sm\:mt-6 {
        margin-top: 1.5rem
    }

    .sm\:mt-auto {
        margin-top: auto
    }

    .sm\:mt-4 {
        margin-top: 1rem
    }

    .sm\:mr-\[2px\] {
        margin-right: 2px
    }

    .sm\:ml-\[2px\] {
        margin-left: 2px
    }

    .sm\:mt-0 {
        margin-top: 0
    }

    .sm\:mt-2 {
        margin-top: .5rem
    }

    .sm\:mr-auto {
        margin-right: auto
    }

    .sm\:block {
        display: block
    }

    .sm\:flex {
        display: flex
    }

    .sm\:hidden {
        display: none
    }

    .sm\:h-72 {
        height: 18rem
    }

    .sm\:h-\[70\%\] {
        height: 70%
    }

    .sm\:h-\[120\%\] {
        height: 120%
    }

    .sm\:w-auto {
        width: auto
    }

    .sm\:w-full {
        width: 100%
    }

    .sm\:w-32 {
        width: 8rem
    }

    .sm\:w-\[22\.5rem\] {
        width: 22.5rem
    }

    .sm\:w-72 {
        width: 18rem
    }

    .sm\:max-w-xs {
        max-width: 20rem
    }

    .sm\:max-w-md {
        max-width: 28rem
    }

    .sm\:max-w-sm {
        max-width: 24rem
    }

    .sm\:max-w-none {
        max-width: none
    }

    .sm\:translate-x-0 {
        --tw-translate-x: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .sm\:snap-end {
        scroll-snap-align: end
    }

    .sm\:auto-cols-\[60\%\] {
        grid-auto-columns: 60%
    }

    .sm\:auto-cols-\[43\%\] {
        grid-auto-columns: 43%
    }

    .sm\:auto-cols-\[75\%\] {
        grid-auto-columns: 75%
    }

    .sm\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .sm\:grid-cols-\[2fr\,80px\,80px\,30px\,50px\,70px\] {
        grid-template-columns: 2fr 80px 80px 30px 50px 70px
    }

    .sm\:grid-cols-\[1fr\,70px\] {
        grid-template-columns: 1fr 70px
    }

    .sm\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .sm\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .sm\:grid-cols-\[80px\,2fr\,85px\,130px\,80px\,60px\,40px\] {
        grid-template-columns: 80px 2fr 85px 130px 80px 60px 40px
    }

    .sm\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr))
    }

    .sm\:grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr))
    }

    .sm\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr))
    }

    .sm\:flex-row {
        flex-direction: row
    }

    .sm\:justify-start {
        justify-content: flex-start
    }

    .sm\:justify-end {
        justify-content: flex-end
    }

    .sm\:justify-center {
        justify-content: center
    }

    .sm\:justify-between {
        justify-content: space-between
    }

    .sm\:gap-6 {
        gap: 1.5rem
    }

    .sm\:gap-0 {
        gap: 0px
    }

    .sm\:gap-2 {
        gap: .5rem
    }

    .sm\:gap-1 {
        gap: .25rem
    }

    .sm\:gap-3 {
        gap: .75rem
    }

    .sm\:gap-x-3 {
        -moz-column-gap: .75rem;
        column-gap: .75rem
    }

    .sm\:rounded-lg {
        border-radius: .5rem
    }

    .sm\:rounded-none {
        border-radius: 0
    }

    .sm\:border-\[1px\] {
        border-width: 1px
    }

    .sm\:border-t-\[1px\] {
        border-top-width: 1px
    }

    .sm\:border-gray03 {
        border-color: #c7c7c7
    }

    .sm\:border-neutral02 {
        --tw-border-opacity: 1;
        border-color: rgb(250 250 244 / var(--tw-border-opacity))
    }

    .sm\:bg-transparent {
        background-color: transparent
    }

    .sm\:bg-green04 {
        --tw-bg-opacity: 1;
        background-color: rgb(19 44 46 / var(--tw-bg-opacity))
    }

    .sm\:bg-neutral02 {
        --tw-bg-opacity: 1;
        background-color: rgb(250 250 244 / var(--tw-bg-opacity))
    }

    .sm\:bg-opacity-0 {
        --tw-bg-opacity: 0
    }

    .sm\:bg-gradient-to-r {
        background-image: linear-gradient(to right, var(--tw-gradient-stops))
    }

    .sm\:p-0 {
        padding: 0
    }

    .sm\:p-4 {
        padding: 1rem
    }

    .sm\:p-3 {
        padding: .75rem
    }

    .sm\:p-6 {
        padding: 1.5rem
    }

    .sm\:p-2\.5 {
        padding: .625rem
    }

    .sm\:p-2 {
        padding: .5rem
    }

    .sm\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .sm\:py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem
    }

    .sm\:px-0 {
        padding-left: 0;
        padding-right: 0
    }

    .sm\:py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .sm\:px-2 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .sm\:py-8 {
        padding-top: 2rem;
        padding-bottom: 2rem
    }

    .sm\:py-0 {
        padding-top: 0;
        padding-bottom: 0
    }

    .sm\:py-1 {
        padding-top: .25rem;
        padding-bottom: .25rem
    }

    .sm\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .sm\:px-3 {
        padding-left: .75rem;
        padding-right: .75rem
    }

    .sm\:py-2 {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .sm\:pt-32 {
        padding-top: 8rem
    }

    .sm\:pt-6 {
        padding-top: 1.5rem
    }

    .sm\:pb-5 {
        padding-bottom: 1.25rem
    }

    .sm\:text-left {
        text-align: left
    }

    .sm\:text-right {
        text-align: right
    }

    .sm\:text-2xl {
        font-size: 1.5rem;
        line-height: 2rem
    }

    .sm\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem
    }

    .sm\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem
    }

    .sm\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem
    }

    .sm\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem
    }

    .sm\:text-base {
        font-size: 1rem;
        line-height: 1.5rem
    }

    .sm\:text-xs {
        font-size: .75rem;
        line-height: 1rem
    }

    .sm\:text-neutral02 {
        --tw-text-opacity: 1;
        color: rgb(250 250 244 / var(--tw-text-opacity))
    }

    .sm\:text-green04 {
        --tw-text-opacity: 1;
        color: rgb(19 44 46 / var(--tw-text-opacity))
    }

    .sm\:placeholder\:text-neutral02::-moz-placeholder {
        --tw-text-opacity: 1;
        color: rgb(250 250 244 / var(--tw-text-opacity))
    }

    .sm\:placeholder\:text-neutral02:-ms-input-placeholder {
        --tw-text-opacity: 1;
        color: rgb(250 250 244 / var(--tw-text-opacity))
    }

    .sm\:placeholder\:text-neutral02::placeholder {
        --tw-text-opacity: 1;
        color: rgb(250 250 244 / var(--tw-text-opacity))
    }

    .sm\:hover\:border-neutral02:hover {
        --tw-border-opacity: 1;
        border-color: rgb(250 250 244 / var(--tw-border-opacity))
    }

    .sm\:hover\:text-neutral01:hover {
        --tw-text-opacity: 1;
        color: rgb(240 237 230 / var(--tw-text-opacity))
    }
}

@media (min-width: 768px) {
    .md\:container {
        width: 100%
    }

    @media (min-width: 375px) {
        .md\:container {
            max-width: 375px
        }
    }

    @media (min-width: 640px) {
        .md\:container {
            max-width: 640px
        }
    }

    @media (min-width: 768px) {
        .md\:container {
            max-width: 768px
        }
    }

    @media (min-width: 1024px) {
        .md\:container {
            max-width: 1024px
        }
    }

    @media (min-width: 1280px) {
        .md\:container {
            max-width: 1280px
        }
    }

    @media (min-width: 1536px) {
        .md\:container {
            max-width: 1536px
        }
    }

    .md\:absolute {
        position: absolute
    }

    .md\:bottom-6 {
        bottom: 1.5rem
    }

    .md\:bottom-3 {
        bottom: .75rem
    }

    .md\:left-0 {
        left: 0px
    }

    .md\:order-1 {
        order: 1
    }

    .md\:order-2 {
        order: 2
    }

    .md\:order-3 {
        order: 3
    }

    .md\:col-span-8 {
        grid-column: span 8 / span 8
    }

    .md\:col-span-6 {
        grid-column: span 6 / span 6
    }

    .md\:col-span-4 {
        grid-column: span 4 / span 4
    }

    .md\:col-span-12 {
        grid-column: span 12 / span 12
    }

    .md\:col-span-3 {
        grid-column: span 3 / span 3
    }

    .md\:col-span-7 {
        grid-column: span 7 / span 7
    }

    .md\:col-span-5 {
        grid-column: span 5 / span 5
    }

    .md\:col-span-10 {
        grid-column: span 10 / span 10
    }

    .md\:col-start-3 {
        grid-column-start: 3
    }

    .md\:col-start-4 {
        grid-column-start: 4
    }

    .md\:col-start-2 {
        grid-column-start: 2
    }

    .md\:col-start-9 {
        grid-column-start: 9
    }

    .md\:col-start-5 {
        grid-column-start: 5
    }

    .md\:col-start-1 {
        grid-column-start: 1
    }

    .md\:col-start-7 {
        grid-column-start: 7
    }

    .md\:row-span-7 {
        grid-row: span 7 / span 7
    }

    .md\:row-span-8 {
        grid-row: span 8 / span 8
    }

    .md\:row-span-9 {
        grid-row: span 9 / span 9
    }

    .md\:row-span-10 {
        grid-row: span 10 / span 10
    }

    .md\:row-span-11 {
        grid-row: span 11 / span 11
    }

    .md\:row-span-12 {
        grid-row: span 12 / span 12
    }

    .md\:row-start-1 {
        grid-row-start: 1
    }

    .md\:mr-2 {
        margin-right: .5rem
    }

    .md\:mt-3 {
        margin-top: .75rem
    }

    .md\:mt-2 {
        margin-top: .5rem
    }

    .md\:block {
        display: block
    }

    .md\:flex {
        display: flex
    }

    .md\:grid {
        display: grid
    }

    .md\:hidden {
        display: none
    }

    .md\:h-\[50vh\] {
        height: 50vh
    }

    .md\:h-8 {
        height: 2rem
    }

    .md\:h-\[115\%\] {
        height: 115%
    }

    .md\:w-\[600px\] {
        width: 600px
    }

    .md\:w-8 {
        width: 2rem
    }

    .md\:w-20 {
        width: 5rem
    }

    .md\:w-\[14rem\] {
        width: 14rem
    }

    .md\:w-auto {
        width: auto
    }

    .md\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .md\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .md\:grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr))
    }

    .md\:grid-cols-\[2fr\,120px\,64px\,100px\,64px\,24px\] {
        grid-template-columns: 2fr 120px 64px 100px 64px 24px
    }

    .md\:grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr))
    }

    .md\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr))
    }

    .md\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr))
    }

    .md\:grid-rows-1 {
        grid-template-rows: repeat(1, minmax(0, 1fr))
    }

    .md\:flex-row {
        flex-direction: row
    }

    .md\:flex-col {
        flex-direction: column
    }

    .md\:items-end {
        align-items: flex-end
    }

    .md\:items-center {
        align-items: center
    }

    .md\:justify-start {
        justify-content: flex-start
    }

    .md\:justify-center {
        justify-content: center
    }

    .md\:gap-10 {
        gap: 2.5rem
    }

    .md\:gap-4 {
        gap: 1rem
    }

    .md\:gap-8 {
        gap: 2rem
    }

    .md\:gap-x-20 {
        -moz-column-gap: 5rem;
        column-gap: 5rem
    }

    .md\:gap-y-24 {
        row-gap: 6rem
    }

    .md\:gap-x-3 {
        -moz-column-gap: .75rem;
        column-gap: .75rem
    }

    .md\:p-10 {
        padding: 2.5rem
    }

    .md\:p-3 {
        padding: .75rem
    }

    .md\:p-0 {
        padding: 0
    }

    .md\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .md\:py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem
    }

    .md\:py-24 {
        padding-top: 6rem;
        padding-bottom: 6rem
    }

    .md\:text-left {
        text-align: left
    }

    .md\:text-5xl {
        font-size: 3rem;
        line-height: 1
    }

    .md\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem
    }

    .md\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem
    }

    .md\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem
    }

    .md\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem
    }

    .md\:text-7xl {
        font-size: 4.5rem;
        line-height: 1
    }

    .md\:text-base {
        font-size: 1rem;
        line-height: 1.5rem
    }

    .md\:text-xs {
        font-size: .75rem;
        line-height: 1rem
    }
}

@media (min-width: 1024px) {
    .lg\:container {
        width: 100%
    }

    @media (min-width: 375px) {
        .lg\:container {
            max-width: 375px
        }
    }

    @media (min-width: 640px) {
        .lg\:container {
            max-width: 640px
        }
    }

    @media (min-width: 768px) {
        .lg\:container {
            max-width: 768px
        }
    }

    @media (min-width: 1024px) {
        .lg\:container {
            max-width: 1024px
        }
    }

    @media (min-width: 1280px) {
        .lg\:container {
            max-width: 1280px
        }
    }

    @media (min-width: 1536px) {
        .lg\:container {
            max-width: 1536px
        }
    }

    .lg\:prose-xl {
        font-size: 1.25rem;
        line-height: 1.8
    }

    .lg\:prose-xl :where(p):not(:where([class~="not-prose"] *)) {
        margin-top: 1.2em;
        margin-bottom: 1.2em
    }

    .lg\:prose-xl :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
        font-size: 1.2em;
        line-height: 1.5;
        margin-top: 1em;
        margin-bottom: 1em
    }

    .lg\:prose-xl :where(blockquote):not(:where([class~="not-prose"] *)) {
        margin-top: 1.6em;
        margin-bottom: 1.6em;
        padding-left: 1.0666667em
    }

    .lg\:prose-xl :where(h1):not(:where([class~="not-prose"] *)) {
        font-size: 2.8em;
        margin-top: 0;
        margin-bottom: .8571429em;
        line-height: 1
    }

    .lg\:prose-xl :where(h2):not(:where([class~="not-prose"] *)) {
        font-size: 1.8em;
        margin-top: 1.5555556em;
        margin-bottom: .8888889em;
        line-height: 1.1111111
    }

    .lg\:prose-xl :where(h3):not(:where([class~="not-prose"] *)) {
        font-size: 1.5em;
        margin-top: 1.6em;
        margin-bottom: .6666667em;
        line-height: 1.3333333
    }

    .lg\:prose-xl :where(h4):not(:where([class~="not-prose"] *)) {
        margin-top: 1.8em;
        margin-bottom: .6em;
        line-height: 1.6
    }

    .lg\:prose-xl :where(img):not(:where([class~="not-prose"] *)) {
        margin-top: 2em;
        margin-bottom: 2em
    }

    .lg\:prose-xl :where(video):not(:where([class~="not-prose"] *)) {
        margin-top: 2em;
        margin-bottom: 2em
    }

    .lg\:prose-xl :where(figure):not(:where([class~="not-prose"] *)) {
        margin-top: 2em;
        margin-bottom: 2em
    }

    .lg\:prose-xl :where(figure > *):not(:where([class~="not-prose"] *)) {
        margin-top: 0;
        margin-bottom: 0
    }

    .lg\:prose-xl :where(figcaption):not(:where([class~="not-prose"] *)) {
        font-size: .9em;
        line-height: 1.5555556;
        margin-top: 1em
    }

    .lg\:prose-xl :where(code):not(:where([class~="not-prose"] *)) {
        font-size: .9em
    }

    .lg\:prose-xl :where(h2 code):not(:where([class~="not-prose"] *)) {
        font-size: .8611111em
    }

    .lg\:prose-xl :where(h3 code):not(:where([class~="not-prose"] *)) {
        font-size: .9em
    }

    .lg\:prose-xl :where(pre):not(:where([class~="not-prose"] *)) {
        font-size: .9em;
        line-height: 1.7777778;
        margin-top: 2em;
        margin-bottom: 2em;
        border-radius: .5rem;
        padding: 1.1111111em 1.3333333em
    }

    .lg\:prose-xl :where(ol):not(:where([class~="not-prose"] *)) {
        padding-left: 1.6em
    }

    .lg\:prose-xl :where(ul):not(:where([class~="not-prose"] *)) {
        padding-left: 1.6em
    }

    .lg\:prose-xl :where(li):not(:where([class~="not-prose"] *)) {
        margin-top: .6em;
        margin-bottom: .6em
    }

    .lg\:prose-xl :where(ol > li):not(:where([class~="not-prose"] *)) {
        padding-left: .4em
    }

    .lg\:prose-xl :where(ul > li):not(:where([class~="not-prose"] *)) {
        padding-left: .4em
    }

    .lg\:prose-xl>:where(ul > li p):not(:where([class~="not-prose"] *)) {
        margin-top: .8em;
        margin-bottom: .8em
    }

    .lg\:prose-xl>:where(ul > li > *:first-child):not(:where([class~="not-prose"] *)) {
        margin-top: 1.2em
    }

    .lg\:prose-xl>:where(ul > li > *:last-child):not(:where([class~="not-prose"] *)) {
        margin-bottom: 1.2em
    }

    .lg\:prose-xl>:where(ol > li > *:first-child):not(:where([class~="not-prose"] *)) {
        margin-top: 1.2em
    }

    .lg\:prose-xl>:where(ol > li > *:last-child):not(:where([class~="not-prose"] *)) {
        margin-bottom: 1.2em
    }

    .lg\:prose-xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
        margin-top: .8em;
        margin-bottom: .8em
    }

    .lg\:prose-xl :where(hr):not(:where([class~="not-prose"] *)) {
        margin-top: 2.8em;
        margin-bottom: 2.8em
    }

    .lg\:prose-xl :where(hr + *):not(:where([class~="not-prose"] *)) {
        margin-top: 0
    }

    .lg\:prose-xl :where(h2 + *):not(:where([class~="not-prose"] *)) {
        margin-top: 0
    }

    .lg\:prose-xl :where(h3 + *):not(:where([class~="not-prose"] *)) {
        margin-top: 0
    }

    .lg\:prose-xl :where(h4 + *):not(:where([class~="not-prose"] *)) {
        margin-top: 0
    }

    .lg\:prose-xl :where(table):not(:where([class~="not-prose"] *)) {
        font-size: .9em;
        line-height: 1.5555556
    }

    .lg\:prose-xl :where(thead th):not(:where([class~="not-prose"] *)) {
        padding-right: .6666667em;
        padding-bottom: .8888889em;
        padding-left: .6666667em
    }

    .lg\:prose-xl :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
        padding-left: 0
    }

    .lg\:prose-xl :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
        padding-right: 0
    }

    .lg\:prose-xl :where(tbody td):not(:where([class~="not-prose"] *)) {
        padding: .8888889em .6666667em
    }

    .lg\:prose-xl :where(tbody td:first-child):not(:where([class~="not-prose"] *)) {
        padding-left: 0
    }

    .lg\:prose-xl :where(tbody td:last-child):not(:where([class~="not-prose"] *)) {
        padding-right: 0
    }

    .lg\:prose-xl>:where(:first-child):not(:where([class~="not-prose"] *)) {
        margin-top: 0
    }

    .lg\:prose-xl>:where(:last-child):not(:where([class~="not-prose"] *)) {
        margin-bottom: 0
    }

    .lg\:visible {
        visibility: visible
    }

    .lg\:absolute {
        position: absolute
    }

    .lg\:relative {
        position: relative
    }

    .lg\:sticky {
        position: -webkit-sticky;
        position: sticky
    }

    .lg\:top-0 {
        top: 0px
    }

    .lg\:top-20 {
        top: 5rem
    }

    .lg\:-bottom-\[47px\] {
        bottom: -47px
    }

    .lg\:left-52 {
        left: 13rem
    }

    .lg\:top-\[100px\] {
        top: 100px
    }

    .lg\:-top-6 {
        top: -1.5rem
    }

    .lg\:left-1\/2 {
        left: 50%
    }

    .lg\:right-\[calc\(\(100vw-1024px\)\/2\)\] {
        right: calc((100vw - 1024px)/2)
    }

    .lg\:right-\[calc\(\(100vw-1024px\+500px\)\/2\)\] {
        right: calc((100vw - 524px)/2)
    }

    .lg\:-left-\[35px\] {
        left: -35px
    }

    .lg\:bottom-\[35\%\] {
        bottom: 35%
    }

    .lg\:bottom-6 {
        bottom: 1.5rem
    }

    .lg\:left-6 {
        left: 1.5rem
    }

    .lg\:left-auto {
        left: auto
    }

    .lg\:right-6 {
        right: 1.5rem
    }

    .lg\:-bottom-16 {
        bottom: -4rem
    }

    .lg\:right-0 {
        right: 0px
    }

    .lg\:bottom-0 {
        bottom: 0px
    }

    .lg\:-right-14 {
        right: -3.5rem
    }

    .lg\:left-0 {
        left: 0px
    }

    .lg\:top-8 {
        top: 2rem
    }

    .lg\:top-1\/2 {
        top: 50%
    }

    .lg\:bottom-8 {
        bottom: 2rem
    }

    .lg\:top-16 {
        top: 4rem
    }

    .lg\:bottom-16 {
        bottom: 4rem
    }

    .lg\:left-10 {
        left: 2.5rem
    }

    .lg\:-top-4 {
        top: -1rem
    }

    .lg\:-right-2 {
        right: -.5rem
    }

    .lg\:right-10 {
        right: 2.5rem
    }

    .lg\:top-6 {
        top: 1.5rem
    }

    .lg\:z-10 {
        z-index: 10
    }

    .lg\:order-1 {
        order: 1
    }

    .lg\:order-2 {
        order: 2
    }

    .lg\:-order-2 {
        order: -2
    }

    .lg\:-order-1 {
        order: -1
    }

    .lg\:order-3 {
        order: 3
    }

    .lg\:order-last {
        order: 9999
    }

    .lg\:col-span-12 {
        grid-column: span 12 / span 12
    }

    .lg\:col-span-6 {
        grid-column: span 6 / span 6
    }

    .lg\:col-span-4 {
        grid-column: span 4 / span 4
    }

    .lg\:col-span-5 {
        grid-column: span 5 / span 5
    }

    .lg\:col-span-8 {
        grid-column: span 8 / span 8
    }

    .lg\:col-span-7 {
        grid-column: span 7 / span 7
    }

    .lg\:col-start-4 {
        grid-column-start: 4
    }

    .lg\:col-start-2 {
        grid-column-start: 2
    }

    .lg\:col-start-7 {
        grid-column-start: 7
    }

    .lg\:col-start-8 {
        grid-column-start: 8
    }

    .lg\:col-start-6 {
        grid-column-start: 6
    }

    .lg\:row-span-4 {
        grid-row: span 4 / span 4
    }

    .lg\:row-span-7 {
        grid-row: span 7 / span 7
    }

    .lg\:row-span-8 {
        grid-row: span 8 / span 8
    }

    .lg\:row-span-9 {
        grid-row: span 9 / span 9
    }

    .lg\:row-span-10 {
        grid-row: span 10 / span 10
    }

    .lg\:row-span-11 {
        grid-row: span 11 / span 11
    }

    .lg\:row-span-12 {
        grid-row: span 12 / span 12
    }

    .lg\:row-start-1 {
        grid-row-start: 1
    }

    .lg\:row-start-2 {
        grid-row-start: 2
    }

    .lg\:row-start-3 {
        grid-row-start: 3
    }

    .lg\:mx-auto {
        margin-left: auto;
        margin-right: auto
    }

    .lg\:mt-0 {
        margin-top: 0
    }

    .lg\:mt-10 {
        margin-top: 2.5rem
    }

    .lg\:-mt-0 {
        margin-top: -0px
    }

    .lg\:-mt-11 {
        margin-top: -2.75rem
    }

    .lg\:mt-4 {
        margin-top: 1rem
    }

    .lg\:mb-20 {
        margin-bottom: 5rem
    }

    .lg\:mt-auto {
        margin-top: auto
    }

    .lg\:mt-40 {
        margin-top: 10rem
    }

    .lg\:mr-0 {
        margin-right: 0
    }

    .lg\:ml-auto {
        margin-left: auto
    }

    .lg\:block {
        display: block
    }

    .lg\:flex {
        display: flex
    }

    .lg\:grid {
        display: grid
    }

    .lg\:hidden {
        display: none
    }

    .lg\:aspect-auto {
        aspect-ratio: auto
    }

    .lg\:h-auto {
        height: auto
    }

    .lg\:h-20 {
        height: 5rem
    }

    .lg\:h-\[calc\(100vh-200px\)\] {
        height: calc(100vh - 200px)
    }

    .lg\:h-full {
        height: 100%
    }

    .lg\:h-80 {
        height: 20rem
    }

    .lg\:h-\[45vh\] {
        height: 45vh
    }

    .lg\:h-\[calc\(100vh-120px\)\] {
        height: calc(100vh - 120px)
    }

    .lg\:h-\[500px\] {
        height: 500px
    }

    .lg\:h-\[70vh\] {
        height: 70vh
    }

    .lg\:h-5 {
        height: 1.25rem
    }

    .lg\:h-\[172px\] {
        height: 172px
    }

    .lg\:min-h-\[150px\] {
        min-height: 150px
    }

    .lg\:min-h-0 {
        min-height: 0px
    }

    .lg\:w-full {
        width: 100%
    }

    .lg\:w-\[110\%\] {
        width: 110%
    }

    .lg\:w-\[962px\] {
        width: 962px
    }

    .lg\:w-\[12\%\] {
        width: 12%
    }

    .lg\:w-\[590px\] {
        width: 590px
    }

    .lg\:w-auto {
        width: auto
    }

    .lg\:w-20 {
        width: 5rem
    }

    .lg\:w-1\/3 {
        width: 33.333333%
    }

    .lg\:w-2\/3 {
        width: 66.666667%
    }

    .lg\:w-\[420px\] {
        width: 420px
    }

    .lg\:w-36 {
        width: 9rem
    }

    .lg\:w-5 {
        width: 1.25rem
    }

    .lg\:min-w-\[260px\] {
        min-width: 260px
    }

    .lg\:max-w-full {
        max-width: 100%
    }

    .lg\:max-w-2xl {
        max-width: 42rem
    }

    .lg\:max-w-xl {
        max-width: 36rem
    }

    .lg\:max-w-\[300px\] {
        max-width: 300px
    }

    .lg\:max-w-sm {
        max-width: 24rem
    }

    .lg\:grow {
        flex-grow: 1
    }

    .lg\:-translate-x-1\/2 {
        --tw-translate-x: -50%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .lg\:-translate-x-64 {
        --tw-translate-x: -16rem;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .lg\:translate-x-0 {
        --tw-translate-x: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .lg\:translate-x-64 {
        --tw-translate-x: 16rem;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .lg\:translate-x-2\/3 {
        --tw-translate-x: 66.666667%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .lg\:translate-y-1\/3 {
        --tw-translate-y: 33.333333%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .lg\:translate-y-0 {
        --tw-translate-y: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .lg\:-translate-y-1\/2 {
        --tw-translate-y: -50%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .lg\:translate-y-1\/2 {
        --tw-translate-y: 50%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .lg\:auto-cols-\[calc\(33\.3333333\%-1rem\)\] {
        grid-auto-columns: calc(33.3333333% - 1rem)
    }

    .lg\:grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr))
    }

    .lg\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .lg\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .lg\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .lg\:grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr))
    }

    .lg\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr))
    }

    .lg\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr))
    }

    .lg\:flex-row {
        flex-direction: row
    }

    .lg\:flex-col {
        flex-direction: column
    }

    .lg\:items-start {
        align-items: flex-start
    }

    .lg\:items-end {
        align-items: flex-end
    }

    .lg\:items-center {
        align-items: center
    }

    .lg\:justify-start {
        justify-content: flex-start
    }

    .lg\:justify-end {
        justify-content: flex-end
    }

    .lg\:justify-center {
        justify-content: center
    }

    .lg\:justify-between {
        justify-content: space-between
    }

    .lg\:gap-6 {
        gap: 1.5rem
    }

    .lg\:gap-2 {
        gap: .5rem
    }

    .lg\:gap-4 {
        gap: 1rem
    }

    .lg\:gap-14 {
        gap: 3.5rem
    }

    .lg\:gap-20 {
        gap: 5rem
    }

    .lg\:gap-x-16 {
        -moz-column-gap: 4rem;
        column-gap: 4rem
    }

    .lg\:gap-x-2 {
        -moz-column-gap: .5rem;
        column-gap: .5rem
    }

    .lg\:gap-x-10 {
        -moz-column-gap: 2.5rem;
        column-gap: 2.5rem
    }

    .lg\:overflow-hidden {
        overflow: hidden
    }

    .lg\:whitespace-nowrap {
        white-space: nowrap
    }

    .lg\:rounded-2xl {
        border-radius: 1rem
    }

    .lg\:rounded-lg {
        border-radius: .5rem
    }

    .lg\:rounded-full {
        border-radius: 9999px
    }

    .lg\:border-2 {
        border-width: 2px
    }

    .lg\:border-l-\[1px\] {
        border-left-width: 1px
    }

    .lg\:border-neutral02 {
        --tw-border-opacity: 1;
        border-color: rgb(250 250 244 / var(--tw-border-opacity))
    }

    .lg\:border-l-neutral01 {
        --tw-border-opacity: 1;
        border-left-color: rgb(240 237 230 / var(--tw-border-opacity))
    }

    .lg\:bg-neutral01 {
        --tw-bg-opacity: 1;
        background-color: rgb(240 237 230 / var(--tw-bg-opacity))
    }

    .lg\:p-10 {
        padding: 2.5rem
    }

    .lg\:p-0 {
        padding: 0
    }

    .lg\:p-6 {
        padding: 1.5rem
    }

    .lg\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .lg\:py-24 {
        padding-top: 6rem;
        padding-bottom: 6rem
    }

    .lg\:py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem
    }

    .lg\:py-20 {
        padding-top: 5rem;
        padding-bottom: 5rem
    }

    .lg\:px-0 {
        padding-left: 0;
        padding-right: 0
    }

    .lg\:px-16 {
        padding-left: 4rem;
        padding-right: 4rem
    }

    .lg\:py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .lg\:px-2 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .lg\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .lg\:py-8 {
        padding-top: 2rem;
        padding-bottom: 2rem
    }

    .lg\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .lg\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .lg\:py-0 {
        padding-top: 0;
        padding-bottom: 0
    }

    .lg\:py-3 {
        padding-top: .75rem;
        padding-bottom: .75rem
    }

    .lg\:pb-10 {
        padding-bottom: 2.5rem
    }

    .lg\:pt-0 {
        padding-top: 0
    }

    .lg\:pb-0 {
        padding-bottom: 0
    }

    .lg\:pt-10 {
        padding-top: 2.5rem
    }

    .lg\:pb-16 {
        padding-bottom: 4rem
    }

    .lg\:pt-36 {
        padding-top: 9rem
    }

    .lg\:pb-20 {
        padding-bottom: 5rem
    }

    .lg\:pt-28 {
        padding-top: 7rem
    }

    .lg\:pb-28 {
        padding-bottom: 7rem
    }

    .lg\:pt-20 {
        padding-top: 5rem
    }

    .lg\:pt-4 {
        padding-top: 1rem
    }

    .lg\:pl-\[calc\(\(100vw-1024px\)\/2\)\] {
        padding-left: calc((100vw - 1024px)/2)
    }

    .lg\:pt-3 {
        padding-top: .75rem
    }

    .lg\:text-left {
        text-align: left
    }

    .lg\:text-center {
        text-align: center
    }

    .lg\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem
    }

    .lg\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem
    }

    .lg\:text-base {
        font-size: 1rem;
        line-height: 1.5rem
    }

    .lg\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem
    }

    .lg\:text-xs {
        font-size: .75rem;
        line-height: 1rem
    }

    .lg\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem
    }

    .lg\:text-5xl {
        font-size: 3rem;
        line-height: 1
    }

    .lg\:text-2xl {
        font-size: 1.5rem;
        line-height: 2rem
    }

    .lg\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem
    }

    .lg\:text-6xl {
        font-size: 3.75rem;
        line-height: 1
    }

    .lg\:text-neutral02 {
        --tw-text-opacity: 1;
        color: rgb(250 250 244 / var(--tw-text-opacity))
    }

    .lg\:text-green05 {
        --tw-text-opacity: 1;
        color: rgb(198 232 129 / var(--tw-text-opacity))
    }

    .lg\:text-green04 {
        --tw-text-opacity: 1;
        color: rgb(19 44 46 / var(--tw-text-opacity))
    }

    .lg\:opacity-0 {
        opacity: 0
    }

    .lg\:opacity-100 {
        opacity: 1
    }

    .lg\:shadow-md {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }

    .lg\:transition-all {
        transition-property: all;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s
    }

    .lg\:duration-1000 {
        transition-duration: 1s
    }

    .lg\:hover\:w-\[590px\]:hover {
        width: 590px
    }

    .lg\:hover\:bg-neutral02:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(250 250 244 / var(--tw-bg-opacity))
    }

    .lg\:hover\:text-green04:hover {
        --tw-text-opacity: 1;
        color: rgb(19 44 46 / var(--tw-text-opacity))
    }

    .group:hover .lg\:group-hover\:right-0 {
        right: 0px
    }

    .group:hover .lg\:group-hover\:translate-x-1\/3 {
        --tw-translate-x: 33.333333%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .group:hover .lg\:group-hover\:opacity-100 {
        opacity: 1
    }

    .group:hover .lg\:group-hover\:opacity-0 {
        opacity: 0
    }
}

@media (min-width: 1280px) {
    .xl\:container {
        width: 100%
    }

    @media (min-width: 375px) {
        .xl\:container {
            max-width: 375px
        }
    }

    @media (min-width: 640px) {
        .xl\:container {
            max-width: 640px
        }
    }

    @media (min-width: 768px) {
        .xl\:container {
            max-width: 768px
        }
    }

    @media (min-width: 1024px) {
        .xl\:container {
            max-width: 1024px
        }
    }

    @media (min-width: 1280px) {
        .xl\:container {
            max-width: 1280px
        }
    }

    @media (min-width: 1536px) {
        .xl\:container {
            max-width: 1536px
        }
    }

    .xl\:left-56 {
        left: 14rem
    }

    .xl\:top-\[104px\] {
        top: 104px
    }

    .xl\:-top-0 {
        top: -0px
    }

    .xl\:-left-36 {
        left: -9rem
    }

    .xl\:-right-32 {
        right: -8rem
    }

    .xl\:right-\[calc\(\(100vw-1280px\)\/2\)\] {
        right: calc((100vw - 1280px)/2)
    }

    .xl\:right-\[calc\(\(100vw-1280px\+500px\)\/2\)\] {
        right: calc((100vw - 780px)/2)
    }

    .xl\:right-2 {
        right: .5rem
    }

    .xl\:left-16 {
        left: 4rem
    }

    .xl\:left-6 {
        left: 1.5rem
    }

    .xl\:right-16 {
        right: 4rem
    }

    .xl\:right-6 {
        right: 1.5rem
    }

    .xl\:-left-8 {
        left: -2rem
    }

    .xl\:-bottom-8 {
        bottom: -2rem
    }

    .xl\:order-1 {
        order: 1
    }

    .xl\:col-span-9 {
        grid-column: span 9 / span 9
    }

    .xl\:col-span-3 {
        grid-column: span 3 / span 3
    }

    .xl\:col-span-8 {
        grid-column: span 8 / span 8
    }

    .xl\:col-span-4 {
        grid-column: span 4 / span 4
    }

    .xl\:col-span-6 {
        grid-column: span 6 / span 6
    }

    .xl\:col-span-7 {
        grid-column: span 7 / span 7
    }

    .xl\:col-span-5 {
        grid-column: span 5 / span 5
    }

    .xl\:col-span-10 {
        grid-column: span 10 / span 10
    }

    .xl\:col-start-1 {
        grid-column-start: 1
    }

    .xl\:col-start-4 {
        grid-column-start: 4
    }

    .xl\:col-start-3 {
        grid-column-start: 3
    }

    .xl\:col-start-2 {
        grid-column-start: 2
    }

    .xl\:col-start-8 {
        grid-column-start: 8
    }

    .xl\:col-start-7 {
        grid-column-start: 7
    }

    .xl\:row-span-7 {
        grid-row: span 7 / span 7
    }

    .xl\:row-span-8 {
        grid-row: span 8 / span 8
    }

    .xl\:row-span-9 {
        grid-row: span 9 / span 9
    }

    .xl\:row-span-10 {
        grid-row: span 10 / span 10
    }

    .xl\:row-span-11 {
        grid-row: span 11 / span 11
    }

    .xl\:row-span-12 {
        grid-row: span 12 / span 12
    }

    .xl\:mt-0 {
        margin-top: 0
    }

    .xl\:mt-24 {
        margin-top: 6rem
    }

    .xl\:mt-1 {
        margin-top: .25rem
    }

    .xl\:block {
        display: block
    }

    .xl\:h-96 {
        height: 24rem
    }

    .xl\:h-auto {
        height: auto
    }

    .xl\:h-24 {
        height: 6rem
    }

    .xl\:h-28 {
        height: 7rem
    }

    .xl\:h-14 {
        height: 3.5rem
    }

    .xl\:w-full {
        width: 100%
    }

    .xl\:w-36 {
        width: 9rem
    }

    .xl\:w-\[1220px\] {
        width: 1220px
    }

    .xl\:w-\[744px\] {
        width: 744px
    }

    .xl\:w-24 {
        width: 6rem
    }

    .xl\:w-28 {
        width: 7rem
    }

    .xl\:w-\[calc\(100\%\+4rem\)\] {
        width: calc(100% + 4rem)
    }

    .xl\:w-14 {
        width: 3.5rem
    }

    .xl\:max-w-lg {
        max-width: 32rem
    }

    .xl\:max-w-\[380px\] {
        max-width: 380px
    }

    .xl\:auto-cols-\[calc\(25\%-1\.125rem\)\] {
        grid-auto-columns: calc(25% - 1.125rem)
    }

    .xl\:auto-cols-\[calc\(33\.3333333\%-1\.125rem\)\] {
        grid-auto-columns: calc(33.3333333% - 1.125rem)
    }

    .xl\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .xl\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr))
    }

    .xl\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .xl\:items-start {
        align-items: flex-start
    }

    .xl\:items-end {
        align-items: flex-end
    }

    .xl\:items-center {
        align-items: center
    }

    .xl\:justify-start {
        justify-content: flex-start
    }

    .xl\:gap-20 {
        gap: 5rem
    }

    .xl\:gap-4 {
        gap: 1rem
    }

    .xl\:gap-6 {
        gap: 1.5rem
    }

    .xl\:gap-3 {
        gap: .75rem
    }

    .xl\:p-10 {
        padding: 2.5rem
    }

    .xl\:p-8 {
        padding: 2rem
    }

    .xl\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .xl\:pr-10 {
        padding-right: 2.5rem
    }

    .xl\:pl-\[calc\(\(100vw-1280px\)\/2\)\] {
        padding-left: calc((100vw - 1280px)/2)
    }

    .xl\:pr-5 {
        padding-right: 1.25rem
    }

    .xl\:text-5xl {
        font-size: 3rem;
        line-height: 1
    }

    .xl\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem
    }

    .xl\:text-6xl {
        font-size: 3.75rem;
        line-height: 1
    }

    .xl\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem
    }

    .xl\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem
    }

    .xl\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem
    }

    .xl\:text-base {
        font-size: 1rem;
        line-height: 1.5rem
    }

    .xl\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem
    }

    .xl\:hover\:w-\[744px\]:hover {
        width: 744px
    }

    .group:hover .xl\:group-hover\:-translate-x-4 {
        --tw-translate-x: -1rem;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .group:hover .xl\:group-hover\:translate-x-4 {
        --tw-translate-x: 1rem;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }
}

@media (min-width: 1536px) {
    .\32xl\:relative {
        position: relative
    }

    .\32xl\:-left-16 {
        left: -4rem
    }

    .\32xl\:-right-16 {
        right: -4rem
    }

    .\32xl\:right-\[calc\(\(100vw-1536px\)\/2\)\] {
        right: calc((100vw - 1536px)/2)
    }

    .\32xl\:right-\[calc\(\(100vw-1536px\+300px\)\/2\)\] {
        right: calc((100vw - 1236px)/2)
    }

    .\32xl\:-right-4 {
        right: -1rem
    }

    .\32xl\:top-16 {
        top: 4rem
    }

    .\32xl\:top-0 {
        top: 0px
    }

    .\32xl\:left-0 {
        left: 0px
    }

    .\32xl\:col-span-9 {
        grid-column: span 9 / span 9
    }

    .\32xl\:col-span-3 {
        grid-column: span 3 / span 3
    }

    .\32xl\:col-span-6 {
        grid-column: span 6 / span 6
    }

    .\32xl\:col-span-7 {
        grid-column: span 7 / span 7
    }

    .\32xl\:col-span-5 {
        grid-column: span 5 / span 5
    }

    .\32xl\:col-span-8 {
        grid-column: span 8 / span 8
    }

    .\32xl\:col-start-2 {
        grid-column-start: 2
    }

    .\32xl\:col-start-7 {
        grid-column-start: 7
    }

    .\32xl\:col-start-3 {
        grid-column-start: 3
    }

    .\32xl\:row-span-7 {
        grid-row: span 7 / span 7
    }

    .\32xl\:row-span-8 {
        grid-row: span 8 / span 8
    }

    .\32xl\:row-span-9 {
        grid-row: span 9 / span 9
    }

    .\32xl\:row-span-10 {
        grid-row: span 10 / span 10
    }

    .\32xl\:row-span-11 {
        grid-row: span 11 / span 11
    }

    .\32xl\:row-span-12 {
        grid-row: span 12 / span 12
    }

    .\32xl\:mt-0 {
        margin-top: 0
    }

    .\32xl\:mt-3 {
        margin-top: .75rem
    }

    .\32xl\:block {
        display: block
    }

    .\32xl\:flex {
        display: flex
    }

    .\32xl\:hidden {
        display: none
    }

    .\32xl\:h-full {
        height: 100%
    }

    .\32xl\:w-\[1470px\] {
        width: 1470px
    }

    .\32xl\:w-\[13\%\] {
        width: 13%
    }

    .\32xl\:w-\[860px\] {
        width: 860px
    }

    .\32xl\:w-36 {
        width: 9rem
    }

    .\32xl\:max-w-7xl {
        max-width: 80rem
    }

    .\32xl\:max-w-\[480px\] {
        max-width: 480px
    }

    .\32xl\:translate-x-0 {
        --tw-translate-x: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .\32xl\:auto-cols-\[calc\(25\.55555555\%-1\.125rem\)\] {
        grid-auto-columns: calc(25.55555555% - 1.125rem)
    }

    .\32xl\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .\32xl\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr))
    }

    .\32xl\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .\32xl\:grid-cols-\[2fr\,150px\,64px\,100px\,64px\,24px\] {
        grid-template-columns: 2fr 150px 64px 100px 64px 24px
    }

    .\32xl\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .\32xl\:gap-8 {
        gap: 2rem
    }

    .\32xl\:gap-3 {
        gap: .75rem
    }

    .\32xl\:gap-x-8 {
        -moz-column-gap: 2rem;
        column-gap: 2rem
    }

    .\32xl\:rounded-lg {
        border-radius: .5rem
    }

    .\32xl\:bg-neutral01 {
        --tw-bg-opacity: 1;
        background-color: rgb(240 237 230 / var(--tw-bg-opacity))
    }

    .\32xl\:p-10 {
        padding: 2.5rem
    }

    .\32xl\:p-6 {
        padding: 1.5rem
    }

    .\32xl\:px-24 {
        padding-left: 6rem;
        padding-right: 6rem
    }

    .\32xl\:py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem
    }

    .\32xl\:px-0 {
        padding-left: 0;
        padding-right: 0
    }

    .\32xl\:pl-\[calc\(\(100vw-1536px\)\/2\)\] {
        padding-left: calc((100vw - 1536px)/2)
    }

    .\32xl\:text-left {
        text-align: left
    }

    .\32xl\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem
    }

    .\32xl\:text-5xl {
        font-size: 3rem;
        line-height: 1
    }

    .\32xl\:text-base {
        font-size: 1rem;
        line-height: 1.5rem
    }

    .\32xl\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem
    }

    .\32xl\:hover\:w-\[860px\]:hover {
        width: 860px
    }

    .group:hover .\32xl\:group-hover\:translate-x-0 {
        --tw-translate-x: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }
}

@media (max-width: 1023px) {
    .max-lg\:no-scrollbar::-webkit-scrollbar {
        display: none
    }

    .max-lg\:no-scrollbar {
        -ms-overflow-style: none
    }

    .max-lg\:fixed {
        position: fixed
    }

    .max-lg\:absolute {
        position: absolute
    }

    .max-lg\:inset-0 {
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px
    }

    .max-lg\:bottom-4 {
        bottom: 1rem
    }

    .max-lg\:order-first {
        order: -9999
    }

    .max-lg\:mt-4 {
        margin-top: 1rem
    }

    .max-lg\:flex {
        display: flex
    }

    .max-lg\:grid {
        display: grid
    }

    .max-lg\:hidden {
        display: none
    }

    .max-lg\:h-\[-webkit-fill-available\] {
        height: -webkit-fill-available
    }

    .max-lg\:h-full {
        height: 100%
    }

    .max-lg\:h-\[calc\(100\%-65px\)\] {
        height: calc(100% - 65px)
    }

    .max-lg\:w-screen {
        width: 100vw
    }

    .max-lg\:max-w-xs {
        max-width: 20rem
    }

    .max-lg\:select-none {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none
    }

    .max-lg\:snap-x {
        -ms-scroll-snap-type: x var(--tw-scroll-snap-strictness);
        scroll-snap-type: x var(--tw-scroll-snap-strictness)
    }

    .max-lg\:snap-mandatory {
        --tw-scroll-snap-strictness: mandatory
    }

    .max-lg\:snap-center {
        scroll-snap-align: center
    }

    .max-lg\:auto-cols-\[80\%\] {
        grid-auto-columns: 80%
    }

    .max-lg\:auto-cols-\[auto\] {
        grid-auto-columns: auto
    }

    .max-lg\:auto-cols-\[75\%\] {
        grid-auto-columns: 75%
    }

    .max-lg\:auto-cols-\[40\%\] {
        grid-auto-columns: 40%
    }

    .max-lg\:grid-flow-col {
        grid-auto-flow: column
    }

    .max-lg\:flex-col {
        flex-direction: column
    }

    .max-lg\:items-center {
        align-items: center
    }

    .max-lg\:justify-center {
        justify-content: center
    }

    .max-lg\:gap-6 {
        gap: 1.5rem
    }

    .max-lg\:gap-3 {
        gap: .75rem
    }

    .max-lg\:gap-8 {
        gap: 2rem
    }

    .max-lg\:overflow-x-auto {
        overflow-x: auto
    }

    .max-lg\:scroll-smooth {
        scroll-behavior: smooth
    }

    .max-lg\:p-3 {
        padding: .75rem
    }

    .max-lg\:px-3 {
        padding-left: .75rem;
        padding-right: .75rem
    }

    .max-lg\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .max-lg\:text-center {
        text-align: center
    }

    .max-lg\:leading-\[0px\] {
        line-height: 0px
    }
}

@media (max-width: 767px) {
    .max-md\:absolute {
        position: absolute
    }

    .max-md\:relative {
        position: relative
    }

    .max-md\:top-4 {
        top: 1rem
    }

    .max-md\:right-0 {
        right: 0px
    }

    .max-md\:order-1 {
        order: 1
    }

    .max-md\:order-4 {
        order: 4
    }

    .max-md\:order-2 {
        order: 2
    }

    .max-md\:order-3 {
        order: 3
    }

    .max-md\:order-last {
        order: 9999
    }

    .max-md\:-mt-\[140px\] {
        margin-top: -140px
    }

    .max-md\:flex {
        display: flex
    }

    .max-md\:grid {
        display: grid
    }

    .max-md\:w-1\/2 {
        width: 50%
    }

    .max-md\:select-none {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none
    }

    .max-md\:snap-x {
        -ms-scroll-snap-type: x var(--tw-scroll-snap-strictness);
        scroll-snap-type: x var(--tw-scroll-snap-strictness)
    }

    .max-md\:snap-mandatory {
        --tw-scroll-snap-strictness: mandatory
    }

    .max-md\:auto-cols-\[40\%\] {
        grid-auto-columns: 40%
    }

    .max-md\:auto-cols-\[55\%\] {
        grid-auto-columns: 55%
    }

    .max-md\:auto-cols-\[60\%\] {
        grid-auto-columns: 60%
    }

    .max-md\:grid-flow-col {
        grid-auto-flow: column
    }

    .max-md\:flex-wrap {
        flex-wrap: wrap
    }

    .max-md\:gap-6 {
        gap: 1.5rem
    }

    .max-md\:gap-8 {
        gap: 2rem
    }

    .max-md\:gap-4 {
        gap: 1rem
    }

    .max-md\:gap-y-4 {
        row-gap: 1rem
    }

    .max-md\:overflow-x-auto {
        overflow-x: auto
    }

    .max-md\:scroll-smooth {
        scroll-behavior: smooth
    }

    .max-md\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .max-md\:first-of-type\:border-t-\[1px\]:first-of-type {
        border-top-width: 1px
    }
}

@media (max-width: 639px) {
    .max-sm\:no-scrollbar::-webkit-scrollbar {
        display: none
    }

    .max-sm\:no-scrollbar {
        -ms-overflow-style: none
    }

    .max-sm\:-left-6 {
        left: -1.5rem
    }

    .max-sm\:-mt-6 {
        margin-top: -1.5rem
    }

    .max-sm\:-mt-10 {
        margin-top: -2.5rem
    }

    .max-sm\:grid {
        display: grid
    }

    .max-sm\:w-screen {
        width: 100vw
    }

    .max-sm\:select-none {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none
    }

    .max-sm\:snap-x {
        -ms-scroll-snap-type: x var(--tw-scroll-snap-strictness);
        scroll-snap-type: x var(--tw-scroll-snap-strictness)
    }

    .max-sm\:snap-mandatory {
        --tw-scroll-snap-strictness: mandatory
    }

    .max-sm\:auto-cols-\[75\%\] {
        grid-auto-columns: 75%
    }

    .max-sm\:auto-cols-\[auto\] {
        grid-auto-columns: auto
    }

    .max-sm\:auto-cols-\[85\%\] {
        grid-auto-columns: 85%
    }

    .max-sm\:auto-cols-\[115px\] {
        grid-auto-columns: 115px
    }

    .max-sm\:auto-cols-\[100\%\] {
        grid-auto-columns: 100%
    }

    .max-sm\:grid-flow-col {
        grid-auto-flow: column
    }

    .max-sm\:gap-4 {
        gap: 1rem
    }

    .max-sm\:gap-6 {
        gap: 1.5rem
    }

    .max-sm\:overflow-x-auto {
        overflow-x: auto
    }

    .max-sm\:scroll-smooth {
        scroll-behavior: smooth
    }

    .max-sm\:bg-neutral02 {
        --tw-bg-opacity: 1;
        background-color: rgb(250 250 244 / var(--tw-bg-opacity))
    }

    .max-sm\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .max-sm\:pb-6 {
        padding-bottom: 1.5rem
    }

    .max-sm\:shadow-\[-100px_0_0_40px_rgba\(250\,250\,244\,1\)\,100px_0_0_40px_rgba\(250\,250\,244\,1\)\] {
        --tw-shadow: -100px 0 0 40px rgba(250, 250, 244, 1), 100px 0 0 40px rgba(250, 250, 244, 1);
        --tw-shadow-colored: -100px 0 0 40px var(--tw-shadow-color), 100px 0 0 40px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }
}

@media (max-width: 375px) {
    .max-xs\:auto-cols-\[85\%\] {
        grid-auto-columns: 85%
    }
}

/*# sourceMappingURL=/s/files/1/0572/9635/5381/files/application.css.map?v=1658303246 */